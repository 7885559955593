import { Stack, Typography } from "@mui/material";

type Props = {
  memo?: string;
  description?: string;
};

export default function PayLinkMemoDescription({ memo, description }: Props) {
  return (
    <Stack spacing={2}>
      {memo && (
        <Typography color={"text.secondary"} variant="small">
          {memo}
        </Typography>
      )}
      {description && (
        <Typography color={"text.secondary"} variant="small">
          {description}
        </Typography>
      )}
    </Stack>
  );
}
