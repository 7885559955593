import Dinero from "dinero.js";

function priceFormatter() {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return function displayPrice(value: number | bigint, options?: { displayZeroAsFree?: boolean; currency?: string }) {
    if (options?.displayZeroAsFree && !value) {
      return "Free";
    }

    const c = options?.currency || "USD";

    // incoming data is an integer, we can use dinero
    if (Number(value) % 1 === 0) {
      const d = Dinero({ amount: Number(value), currency: c as Dinero.Currency });
      return d.toFormat("$0,0.00");
    }

    // Fallback on old formatting
    return formatter.format(value);
  };
}

export default priceFormatter();
