import { inDevTestLocal } from "@utils/inDevTestLocal";
import { Dispatch, SetStateAction } from "react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface Resettable {
  reset(): void;
}

/**
 * The important states of the stepup handler are:
 * - User has received issuer stepup challenge -> valid URL for stepup source and iframe is not hidden.
 *   - User interaction should be focused on the issuer content until completed.
 * - User has completed the stepup challenge -> stepup complete, and success is based on issuer response.
 *   - Application is attempting to complete card linking -> stepup complete, was successful.
 *     - iframe can be hidden (no user interaction needed anymore), but should block user interaction until finished.
 *   - 3CSC failed -> stepup complete, not successful.
 *     - iframe hidden, user interaction unblocked, but cannot use the selected card or issuer for card linking.
 */
type StepupHandlerType = {
  iframeHidden: boolean;
  success: boolean;
  stepupComplete: boolean;
  stepupSrc: string | URL;
};

interface StepupHandlerStore extends StepupHandlerType, Resettable {
  setIframeHidden: Dispatch<SetStateAction<boolean>>;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setStepupComplete: Dispatch<SetStateAction<boolean>>;
  setStepupSrc: (val: string | URL) => void;
}

const initialState: StepupHandlerType = {
  iframeHidden: true,
  success: false,
  stepupComplete: false,
  stepupSrc: "",
};

const useStepupHandlerStore = create<StepupHandlerStore>()(
  devtools(
    (set, get) => ({
      ...initialState,
      reset() {
        set(initialState);
      },
      setIframeHidden(value) {
        if (typeof value === "function") {
          set({
            iframeHidden: value(get().iframeHidden),
          });
          return;
        }

        set({ iframeHidden: value });
      },
      setSuccess(value) {
        if (typeof value === "function") {
          set({
            success: value(get().success),
          });
          return;
        }

        set({ success: value });
      },
      setStepupComplete(value) {
        if (typeof value === "function") {
          set({
            stepupComplete: value(get().stepupComplete),
          });
          return;
        }

        set({ stepupComplete: value });
      },
      setStepupSrc(stepupSrc) {
        set({ stepupSrc });
      },
    }),
    { enabled: inDevTestLocal, name: "stepupHandlerStore" },
  ),
);

export default useStepupHandlerStore;
