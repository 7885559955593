import { ComponentOptions } from "../@types/util";

const MuiFormControlLabel: ComponentOptions<"MuiFormControlLabel"> = {
  styleOverrides: {
    label: {
      fontSize: 14,
    },
  },
};

const components = { MuiFormControlLabel };
export default components;
