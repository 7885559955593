import { ComponentOptions } from "../@types/util";
import { fontWeights } from "../base/typography";
const MuiCheckbox: ComponentOptions<"MuiCheckbox"> = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      marginRight: "8px",
      marginBottom: "auto",
      padding: "9.5px",
      marginLeft: "7px",
      "& .MuiSvgIcon-root": {
        fontSize: 21,
      },
      "&.Mui-checked": {
        color: theme.palette.link.main,
        " + .MuiFormControlLabel-label": {
          fontWeight: fontWeights.bold,
        },
      },
      "&.MuiCheckbox-indeterminate": {
        "+ .MuiFormControlLabel-label": {
          fontWeight: fontWeights.bold,
        },
      },
      "&.Mui-disabled": {
        ".MuiSvgIcon-root": {
          ".checked-icon-center, .checked-icon-border, .checked-icon-checkmark, .indeterminate-icon-center, .indeterminate-icon-border":
            {
              stroke: theme.palette.text.disabled,
            },
          ".indeterminate-icon-fill, .checked-icon-fill": {
            fill: theme.palette.background.light,
          },
        },
      },
      "&:hover": {
        background: theme.palette.brand.light,
        borderRadius: "6px",
      },
      "&.Mui-focusVisible": {
        background: theme.palette.brand.light,
        height: "24px",
        width: "24px",
        borderRadius: "6px",
      },
    }),
  },
};

const components = { MuiCheckbox };
export default components;
