import { BaseSchema } from "yup";

import { MESSAGE_NAMES } from "./parentWindowCommsConstants";

export const fanoutMessage = (
  domains: string[],
  message: { name: (typeof MESSAGE_NAMES)[keyof typeof MESSAGE_NAMES]; payload?: unknown },
  options?: {
    context?: Window;
  },
): void => {
  const { context = window.parent } = options || {};
  domains.forEach((domain) => context.postMessage(message, domain));
};

/**
 * Posting a message to the parent window with all allowed domains
 * @param domains - a list of allowed domains
 * @param message - a message to send to the parent window
 * @param expectedResponseName - a message name expected to be a response
 * @param options
 */
export const fanoutTransactionalMessage = async <T>(
  domains: string[],
  message: { name: (typeof MESSAGE_NAMES)[keyof typeof MESSAGE_NAMES]; payload?: unknown },
  expectedResponseName: (typeof MESSAGE_NAMES)[keyof typeof MESSAGE_NAMES],
  options?: {
    timeout?: number;
    context?: Window;
    responseSchema?: BaseSchema;
  },
) => {
  const { context = window.parent, timeout = 2000, responseSchema } = options || {};
  const promises = domains.map(
    (domain) =>
      new Promise<T | null>((res, rej) => {
        // Send a message to the parent window along with a response message port
        const { port1, port2 } = new MessageChannel();
        context.postMessage(message, domain, [port2]);

        port1.onmessage = (event: MessageEvent) => {
          if (event.data.name === expectedResponseName) {
            const payload = <T>event.data.payload;

            // Validate payload schema if needed
            if (responseSchema) {
              try {
                responseSchema.validateSync(payload);
                res(payload);
              } catch (e) {
                rej(e);
              }
            } else {
              res(payload);
            }
          } else {
            rej(new Error(`Unexpected response, got "${event.data.name}", expected "${expectedResponseName}"`));
          }

          port1.close();
          port2.close();
        };
      }),
  );

  promises.push(new Promise((_, rej) => setTimeout(rej, timeout, new Error(`Timeout`))));

  return Promise.race(promises);
};
