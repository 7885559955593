import { Stack } from "@mui/material";
import OrderLine from "./OrderLine";

const PartialAmount = ({ partialAmount }: { partialAmount: number }) => {
  return (
    <Stack data-testid="partial-amount" style={{ marginTop: "2rem" }} spacing={1}>
      <Stack typography="large" sx={{ fontWeight: "700" }}>
        Your order was partially paid
      </Stack>
      <Stack>The merchant will contact you to collect the remaining payment.</Stack>
      <OrderLine label="Paid amount" lineTotal={partialAmount} currency="USD" typography="xl" bold />
    </Stack>
  );
};

export default PartialAmount;
