import { object, string } from "yup";

export const enrollmentInfoResponseSchema = object({
  email: string().email().required(),
  phone: string().matches(/\d{10}/),
  shippingAddress: object({
    address1: string(),
    address2: string(),
    city: string(),
    firstName: string(),
    lastName: string(),
    phoneNumber: string().matches(/\d{10}/),
    state: string(),
    zipCode: string().matches(/^((\d{5}-\d{4})|(\d{5})|(\d{9})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/), // copied from @components/AddressForm/addressSchema.ts for consistency
    //todo: unify regex used for zipCode validation across the app
  }),
});

export const returningUserInfoResponseSchema = object({
  transactionId: string().required(),
  email: string().email().required(),
  phone: string().matches(/\d{10}/),
});
