import React, { FC } from "react";
import { CardLinkageType, ConsolidatedPaymentMethod, LinkedCard, ManualCard } from "@models/card";
import { Grid, Link, Radio, Stack } from "@mui/material";
import Card from "../Card";
import { CardSize } from "@modules/payment";
import { useFlags } from "@hooks/ldWithOverrides";
import getEnv from "@utils/getEnv";
import { SAMSUNG_DEMO_CARD_ART_URL, SAMSUNG_DEMO_LAST_FOUR } from "@constants/samsungDemo";

interface PaymentListRowProps {
  item: ConsolidatedPaymentMethod;
  checked: boolean;
  onSelect: (selected: ConsolidatedPaymentMethod) => void;
  onEdit?: (selected: LinkedCard | ManualCard) => void;
  isDefault?: boolean;
  disabled?: boolean;
  dashboard?: boolean;
}

const CARD_NICKNAME_ENABLED = getEnv().CARD_LINKED_NICKNAMES_ENABLED === "true";

const PaymentListRow: FC<PaymentListRowProps> = ({
  item,
  onSelect,
  onEdit,
  checked,
  isDefault,
  disabled,
  dashboard = false,
}) => {
  // For Samsung demo
  const flags = useFlags();
  const samsungDemoOverride = flags.samsungDemo && isDefault;

  const metadata = {
    lastFour: item.last_four,
    description: item.description,
    cardArt: item.card_art_url,
    network: item.network_type,
  };

  if (samsungDemoOverride) {
    // For Samsung demo
    metadata.cardArt = SAMSUNG_DEMO_CARD_ART_URL;
    metadata.lastFour = SAMSUNG_DEMO_LAST_FOUR;
  }

  //display user provided card_name if it exits
  const cardName = (CARD_NICKNAME_ENABLED || !item.card_linked) && item.card_nickname ? item.card_nickname : undefined;

  return (
    <Grid container role="listitem">
      <Grid item flex={1} sx={{ paddingRight: 4 }} onClick={() => onSelect(item)} data-testid="payment-list-row">
        <Stack direction="row" alignItems="flex-start" sx={{ cursor: "pointer", ml: "-11px" }}>
          <Radio checked={checked} disabled={disabled} />
          <Card
            cardCarousel={!dashboard}
            disabled={disabled}
            cardName={cardName}
            isDefault={isDefault}
            size={CardSize.Medium}
            {...metadata}
          />
        </Stack>
      </Grid>
      <Grid item textAlign="right">
        {(item.card_linkage_type === CardLinkageType.UserCard ||
          item.card_linkage_type === CardLinkageType.LinkedCard) &&
          !disabled &&
          onEdit && (
            <Link role="button" variant="button" onClick={() => onEdit(item)}>
              Edit
            </Link>
          )}
      </Grid>
    </Grid>
  );
};

export default PaymentListRow;
