import { ComponentOptions } from "../@types/util";
import { alpha } from "@mui/material/styles";

const MuiButton: ComponentOptions<"MuiButton"> = {
  defaultProps: {
    disableElevation: true,
    size: "large",
  },
  styleOverrides: {
    root: ({ theme }) => ({
      textTransform: "none",
      textDecoration: "none",
      fontWeight: theme.typography.fontWeightBold,
      height: 48,
      paddingLeft: 26,
      paddingRight: 26,
      borderRadius: 51,
      ...theme.typography.medium,

      // focus-visible allow us to set different focus style for keyboard tabbing
      // see: https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
      // we only want a blue outline for keyboard only users for better accessibility
      ":focus-visible": {
        outline: `4px solid ${alpha(theme.palette.brand.main, 0.1)}`,
        boxShadow: `0px 0px 4px 0px ${theme.palette.brand.light}, 0px 0px 2px 0px ${alpha(
          theme.palette.primary.dark,
          0.25,
        )}`,
        ".MuiTouchRipple-root": {
          display: "none",
        },
      },
    }),

    contained: ({ theme }) => ({
      color: theme.palette.text.white,
      backgroundColor: theme.palette.background.dark,
      "&:hover": {
        backgroundColor: alpha(theme.palette.background.dark, 0.83),
      },
      ":disabled": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
      },
    }),

    outlined: ({ theme }) => ({
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.light,
      borderColor: theme.palette.primary.light,
      "&:hover": {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.primary.dark,
      },
      ":disabled": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.primary.light,
      },
    }),

    text: ({ theme }) => ({
      height: 36,
      borderRadius: 56,
      color: theme.palette.link.main,
      textDecoration: "underline",
      padding: "10px 12px",
      "&:hover": {
        backgroundColor: theme.palette.brand.light,
        textDecoration: "underline",
      },
    }),
  },
};

const components = { MuiButton };
export default components;
