import { ComponentOptions } from "../@types/util";

const MuiLink: ComponentOptions<"MuiLink"> = {
  defaultProps: {
    fontSize: "14px",
    color: "link.main",
    fontWeight: "bold",
    lineHeight: "140%",
    textTransform: "none",
  },
  styleOverrides: {
    root: {
      cursor: "pointer",
    },
  },
};

const components = { MuiLink };
export default components;
