import { AxiosStatic, AxiosInstance } from "axios";
import axiosRetry, { IAxiosRetryConfig, isNetworkError } from "axios-retry";

const WHITELIST_RETRY_CODE_ERRORS = [408, 502, 503, 504];
const IDEMPOTENT_HTTP_METHODS = ["get", "head", "options", "put", "delete"];

const customAxiosRetry = (api: AxiosStatic | AxiosInstance, retryConfig: IAxiosRetryConfig = {}) =>
  axiosRetry(api, {
    retryDelay: axiosRetry.exponentialDelay,
    retries: 3,
    retryCondition: (error) => {
      if (isNetworkError(error)) return true;
      //without this we could not determine if the request can be retried
      if (!error.config || error.response?.status === undefined || error.config.method === undefined) return false;
      return (
        IDEMPOTENT_HTTP_METHODS.includes(error.config.method) &&
        WHITELIST_RETRY_CODE_ERRORS.includes(error.response.status)
      );
    },
    ...retryConfig,
  });

export default customAxiosRetry;
