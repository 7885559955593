import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { UNINITIALIZED_CALL } from "@hooks/useParentWindowComms";
import { inDevTestLocal } from "@utils/inDevTestLocal";
export type LoadingStore = {
  loaderVisible: boolean;
  setLoaderVisible: (state: boolean) => void;
  percentage: number;
  loadingError: string;
  incrementPercentage: (change: number) => void;
  setLoadingError: (errorMessage: string) => void;
};

const useLoadingStore = create<LoadingStore>()(
  devtools(
    (set, get) => ({
      loaderVisible: true,
      percentage: 0,
      loadingError: "",
      incrementPercentage(change: number) {
        const current = get().percentage;
        current + change > 100 ? set({ percentage: 100 }) : set({ percentage: current + change });
      },
      setLoadingError(errorMessage) {
        // Ignore uninitialized call (iframe comms call without allowed domains)
        if (!errorMessage.includes(UNINITIALIZED_CALL)) {
          set({ loadingError: errorMessage });
        }
      },
      setLoaderVisible(state: boolean) {
        set({ loaderVisible: state });
      },
    }),
    { enabled: inDevTestLocal, name: "loadingStore" },
  ),
);

export default useLoadingStore;
