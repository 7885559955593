import { ReactNode, useEffect } from "react";
import { AppBar, Box, Container, Grid, Stack } from "@mui/material";
import useResizeObserver from "use-resize-observer";
import useParentWindowComms from "@hooks/useParentWindowComms";
import useMerchantConfigStore from "@stores/merchantConfigStore";
import { Footer } from "@components/Footer";
import mint from "@images/skipify_logos/mint.svg";
import Image from "next/image";
import useSkipifyLayer from "@hooks/useSkipifyLayer";

const HEADER_HEIGHT = "86px";

export const BasicHeader = () => (
  <AppBar
    data-testid="basic-header"
    color="inherit"
    position="sticky"
    elevation={0}
    sx={{ padding: "22px 24px", height: HEADER_HEIGHT, zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <Stack sx={{ height: HEADER_HEIGHT }} direction="row" justifyContent="space-between" alignItems="center">
      <Image alt="Skipify" src={mint.src} width="40" height="40" />
    </Stack>
  </AppBar>
);

export const BasicFooter = () => <Footer dataTestid="basic-footer" />;

export const BasicContent = ({ children }: { children?: ReactNode }) => (
  <Grid data-testid="basic-content" item xs={12} md={5}>
    <Stack direction="column">{children}</Stack>
  </Grid>
);

type BasicLayoutProps = {
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  renderContent?: (children?: ReactNode) => ReactNode;
};

const BasicLayout = ({
  children,
  header = <BasicHeader />,
  footer = <BasicFooter />,
  renderContent = (children?: ReactNode) => <BasicContent>{children}</BasicContent>,
}: BasicLayoutProps) => {
  const { ref: contentRef, height: contentHeight = 0 } = useResizeObserver<HTMLDivElement>();
  const { ref: footerRef, height: footerHeight = 0 } = useResizeObserver<HTMLDivElement>();
  const allowedDomains = useMerchantConfigStore((state) => state.allowedDomains);
  const { resizeContainer } = useParentWindowComms({ allowedDomains });

  useEffect(() => {
    const totalHeight = footerHeight + contentHeight;
    if (totalHeight <= 0 || !isFinite(totalHeight)) {
      return;
    }

    resizeContainer(String(footerHeight + contentHeight));
  }, [footerHeight, contentHeight, resizeContainer]);

  const skipifyLayer = useSkipifyLayer();
  return (
    <Container sx={{ px: skipifyLayer ? 0 : 2 }} maxWidth="md" disableGutters data-testid="basic-layout">
      <Stack direction="column" justifyContent="space-between">
        <Stack ref={contentRef} pb={`${footerHeight}px`} height="100%">
          {header}
          <Grid container direction="row" justifyContent="space-between" spacing={0} wrap="nowrap" minHeight="144px">
            {renderContent(children)}
          </Grid>
        </Stack>
        <Stack
          sx={{
            px: skipifyLayer ? 2 : 0,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#fff",
            zIndex: (theme) => theme.zIndex.drawer - 50,
          }}
          direction="row"
          justifyContent="center"
        >
          <Box ref={footerRef} width="100%">
            {footer}
          </Box>
        </Stack>
      </Stack>
    </Container>
  );
};

export default BasicLayout;
