import { ReactNode } from "react";
import { Box, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { OrderSummary } from "@components/OrderSummary";
import BasicLayout from "./BasicLayout";
import { Order } from "@models/order";
import { useRouter } from "next/router";
import OrderSummaryAccordion from "@components/OrderSummary/OrderSummaryAccordion";
import useSkipifyLayer from "@hooks/useSkipifyLayer";
import useMerchantConfigStore from "@stores/merchantConfigStore";

export type LayoutProps = {
  children?: ReactNode;
  order?: Order;
  showOrderSummary?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
};

// This layout layer passes down header and footer to basic layout, displays order summary/cart as appropriate
// and is aware of screen size for setting breakpoints.
export default function Layout({ children, order, header, footer, showOrderSummary = true }: LayoutProps) {
  const isDesktopScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const router = useRouter();
  const orderCompletePath = router.pathname.includes("ordercomplete");
  const reviewPayPath = router.pathname.includes("reviewpay");
  const isPaylink = router.pathname.includes("paylink");
  const skipifyLayer = useSkipifyLayer();
  // When enabled, cart edits are not allowed
  const { enabled: editCartNotAllowed } = useMerchantConfigStore((store) => store.cartConfigs.DISALLOW_CART_EDITS);
  // Cart items can be removed when flow is not paylinks and when a merchant allows it in their configs
  const canItemBeRemoved = !isPaylink && !editCartNotAllowed;

  const canItemBeEditable = canItemBeRemoved && Boolean(reviewPayPath && order?.pricing.total);

  return (
    <BasicLayout
      header={header}
      footer={footer}
      renderContent={(children) => (
        <>
          <Grid item xs={12} md={5}>
            {skipifyLayer ? (
              <>
                {showOrderSummary && !isDesktopScreen && order && (
                  <OrderSummaryAccordion
                    order={order}
                    quantityEditable={canItemBeEditable}
                    showRemove={canItemBeRemoved}
                  />
                )}
                <Box pt={2} px={2}>
                  {children}
                </Box>
              </>
            ) : (
              children
            )}
          </Grid>
          {isDesktopScreen && order && (
            <Grid item md={5} mt="55px">
              {orderCompletePath && <Typography variant="subtitle2">Order summary</Typography>}
              <OrderSummary
                order={order}
                bgcolor="primary.light"
                padding={3}
                quantityEditable={canItemBeEditable}
                showRemove={canItemBeRemoved}
              />
            </Grid>
          )}
        </>
      )}
    >
      {children}
    </BasicLayout>
  );
}
