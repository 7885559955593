import { CompleteOrderResponse, Order, OrderCompleteErrors, UpdateOrderRequest } from "@models/order";
import skipifyEvents from "@services/skipifyEvents";
import {
  claimOrder,
  completeOrder,
  createOrder,
  deleteOrderItem,
  fetchOrder,
  reviewOrder,
  updateOrder,
  updateReviewOrder,
} from "@services/order";
import { inDevTestLocal } from "@utils/inDevTestLocal";
import { validateOrderUpdate } from "@utils/orderUtils";
import axios, { AxiosError } from "axios";
import { cloneDeep, set as setProperty } from "lodash";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { usePaymentStore } from "../../modules/payment";

export enum OrderProcessErrors {
  UPDATING_ORDER = "updating your order",
  CONFIRMING_ORDER = "confirming your order",
  UPDATING_DELIVERY_OPTIONS = "updating the delivery options",
  NO_PAYMENT_METHOD = "accessing your selected payment method",
}

type OrderStoreNonFunctionalFields = {
  order?: Order;
  loading: boolean;
  paymentError?: string;
  processing: boolean;
  reviewing: boolean;
  acceptedOrderTerms: boolean;
  orderDirty: boolean;
  fetchOrderError?: AxiosError;
  enroll: boolean;
  orderProcessError?: OrderProcessErrors;
};

export type OrderStore = OrderStoreNonFunctionalFields & {
  create: (merchantId: string, payload: unknown) => Promise<string>;
  claim: (id: string) => Promise<void>;
  fetch: (id: string) => Promise<void>;
  review: (id: string) => Promise<void>;
  updateReview: (id: string, payload: UpdateOrderRequest) => Promise<void>;
  update: (id: string, payload: UpdateOrderRequest, ignoreDirty?: boolean) => Promise<void>;
  complete: (id: string, splitPaymentValues?: Record<string, number>) => Promise<Array<CompleteOrderResponse>>;
  beginProcessing: () => void;
  endProcessing: () => void;
  acceptTerms: () => void;
  rejectTerms: () => void;
  deleteOrderItem: (orderId: string, itemId: string) => Promise<void>;
  clearAuthorizationError: () => void;
  setDiscountCode: (orderId: string, code: string | null) => Promise<void>;
  setEnroll: (enroll: boolean) => void;
  setOrderProcessError: (error?: OrderProcessErrors) => void;
};

const orderStoreInitialFields: OrderStoreNonFunctionalFields = {
  order: undefined,
  loading: false,
  paymentError: undefined,
  processing: false,
  reviewing: false,
  acceptedOrderTerms: false,
  orderDirty: false,
  fetchOrderError: undefined,
  enroll: false,
};

const useOrderStore = create<OrderStore>()(
  devtools(
    (set, get) => ({
      ...orderStoreInitialFields,
      setEnroll(enroll: boolean) {
        set({ enroll });
      },
      async create(merchantId: string, payload: unknown) {
        set({ loading: true }, false, "beginCreate");
        try {
          const order = await createOrder(merchantId, payload);
          set({ order }, false, "create");
          return order.id;
        } finally {
          set({ loading: false }, false, "endCreate");
        }
      },
      async claim(id: string) {
        set({ loading: true }, false, "beginClaim");
        try {
          await claimOrder(id);
        } finally {
          set({ loading: false }, false, "endClaim");
        }
      },
      async fetch(id: string) {
        set({ loading: true }, false, "beginFetch");
        try {
          const res = await fetchOrder(id);
          set({ order: res, orderDirty: !res.signature, fetchOrderError: undefined }, false, "fetch");
          skipifyEvents.setOrderProperties(res);
        } catch (e) {
          if (axios.isAxiosError(e) && e.response) {
            switch (e.response.status) {
              case 401:
              case 403:
                set({ fetchOrderError: e });
                console.warn(`[orderStore] authorization error while fetching order:`, e);
                return;
            }
          }
          console.error(`[orderStore] failed to fetch order:`, e);
          throw e;
        } finally {
          set({ loading: false }, false, "endFetch");
        }
      },
      async update(id: string, payload: UpdateOrderRequest, ignoreDirty = false) {
        set({ loading: true }, false, "beginUpdate");
        try {
          const res = await updateOrder(id, payload);
          set({ order: res, ...(ignoreDirty ? {} : { orderDirty: true }) }, false, "update");
        } finally {
          set({ loading: false, paymentError: undefined }, false, "endUpdate");
        }
      },
      async complete(id: string) {
        set({ loading: true }, false, "beginOrderComplete");

        const splitPaymentValues = usePaymentStore.getState().splitPaymentValues;
        const order = get().order;
        const payload = {
          signature: order?.signature || "",
          payments: [
            {
              ref: order?.payments?.[0]?.ref || "",
              amount: order?.pricing?.total?.value ?? 0,
            },
          ],
        };

        if (Array.isArray(order?.payments) && order.payments && order.payments.length > 1 && splitPaymentValues) {
          payload.payments = order?.payments?.map((p) => ({
            ref: p.ref,
            amount: splitPaymentValues[p.ref],
          }));
        }

        if (!payload.signature) {
          set({ paymentError: OrderCompleteErrors.SKIPIFY_ERROR }, false, "orderCompleteError");
          throw new Error("no signature in the order");
        }
        try {
          const transactionDetails = await completeOrder(id, payload);
          const order = cloneDeep(get().order);
          if (order) {
            const transactionAmount = transactionDetails?.reduce((sum, transaction) => {
              return sum + transaction.transactionAmount;
            }, 0);
            setProperty(order, "payment.transactionAmount.value", transactionAmount);
            set({ order }, false, "orderComplete");
          }
          set({ paymentError: undefined }, false, "orderComplete");
          return transactionDetails;
        } catch (e) {
          if (e instanceof AxiosError) {
            if (e.response?.status === 400) {
              const errorMessage =
                typeof e.response.data?.error === "string" && e.response.data?.error !== ""
                  ? e.response.data?.error
                  : OrderCompleteErrors.SKIPIFY_ERROR;
              set({
                paymentError: errorMessage,
              });
            }
          }
          throw e;
        } finally {
          set({ loading: false }, false, "endOrderComplete");
        }
      },
      async review(id: string) {
        set({ loading: true, reviewing: true }, false, "beginReview");
        try {
          const res = await reviewOrder(id);
          set({ order: res }, false, "review");
          skipifyEvents.setOrderProperties(res);
        } finally {
          set({ loading: false, reviewing: false, orderDirty: false }, false, "endReview");
        }
      },
      async updateReview(id: string, payload: UpdateOrderRequest) {
        // don't attempt update if payload doesn't change order
        if (!validateOrderUpdate(get().order, payload)) {
          return;
        }

        set({ loading: true, reviewing: true }, false, "beginUpdateReview");
        try {
          const res = await updateReviewOrder(id, payload);
          set({ order: res }, false, "updateReview");
        } finally {
          set({ loading: false, reviewing: false, orderDirty: false }, false, "endUpdateReview");
        }
      },
      beginProcessing() {
        set({ processing: true }, false, "beginProcessing");
      },
      endProcessing() {
        set({ processing: false }, false, "endProcessing");
      },
      acceptTerms() {
        set({ acceptedOrderTerms: true }, false, "acceptTerms");
      },
      rejectTerms() {
        set({ acceptedOrderTerms: false }, false, "rejectTerms");
      },
      setOrderProcessError(error) {
        set({ orderProcessError: error });
      },
      async deleteOrderItem(orderId: string, itemId: string) {
        set({ loading: true, reviewing: true }, false, "beginDeleteOrderItem");
        try {
          const res = await deleteOrderItem(orderId, itemId);
          set({ order: res }, false, "review");
        } finally {
          set({ loading: false, reviewing: false, orderDirty: false }, false, "endDeleteOrderItem");
        }
      },
      clearAuthorizationError() {
        set({ fetchOrderError: undefined });
      },
      async setDiscountCode(orderId: string, code: string | null) {
        // Convert empty string to null to clear the discount
        if (code === "") {
          code = null;
        }

        // Fire only if code has changed
        if (!get().order?.pricing?.discount?.code || code !== get().order?.pricing?.discount?.code) {
          try {
            await get().updateReview(orderId, { discountCode: code });
          } catch (e) {
            // setting a discount code failed - clear it up
            await get().updateReview(orderId, { discountCode: null });
            throw e;
          }
        }
      },
    }),
    { enabled: inDevTestLocal, name: "orderStore" },
  ),
);

export default useOrderStore;
