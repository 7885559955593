import { create } from "zustand";
import { devtools } from "zustand/middleware";

import getEnv from "@utils/getEnv";
import { inDevTestLocal } from "@utils/inDevTestLocal";

type FeatureFlags = {
  rewardPoints: boolean;
  shippingInstructions: boolean;
  cookieLogin: boolean;
  generalFee: boolean;
  surchargeFee: boolean;
};

const useFeatureFlags = create<FeatureFlags>()(
  devtools(
    () => ({
      rewardPoints: getEnv().REWARDS_SECTION_FLAG === "enabled",
      shippingInstructions: getEnv().SHIPPING_INSTRUCTIONS_FLAG === "enabled",
      cookieLogin: getEnv().COOKIE_LOGIN === "enabled",
      generalFee: getEnv().GENERAL_FEE_FLAG === "enabled",
      surchargeFee: getEnv().SURCHARGE_FEE_FLAG === "enabled",
    }),
    { enabled: inDevTestLocal, name: "featureFlags" },
  ),
);

export default useFeatureFlags;
