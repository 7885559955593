import { createTheme } from "@mui/material/styles";
import components from "./components";
import palette from "./base/palette";
import typography from "./base/typography";
import shadows from "./base/shadows";
import { mergeShadows } from "./utils/mergeShadows";

const diagonal = createTheme({
  components,
  typography,
  palette,
});

diagonal.shadows = mergeShadows(diagonal.shadows, shadows);

export default diagonal;
