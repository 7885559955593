import { inDevTestLocal } from "@utils/inDevTestLocal";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type LayoutStore = {
  showFooter: boolean;
  showHeaderCloseIcon: boolean;
  updateShowFooter: (showFooter: boolean) => void;
  updateShowHeaderCloseIcon: (showHeaderCloseIcon: boolean) => void;
};

const useLayoutStore = create<LayoutStore>()(
  devtools(
    (set) => ({
      showFooter: true,
      updateShowFooter: (showFooter: boolean) => set(() => ({ showFooter: showFooter })),
      showHeaderCloseIcon: false,
      updateShowHeaderCloseIcon: (showHeaderCloseIcon: boolean) => set(() => ({ showHeaderCloseIcon })),
    }),
    { enabled: inDevTestLocal, name: "LayoutStore" },
  ),
);

export default useLayoutStore;
