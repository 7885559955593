import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";

import samsungLogo from "@images/partner_logos/samsung-logo.png";
import PoweredBySkipifyIcon from "@icons/PoweredBySkipifyIcon";

const SamsungDemoLoadingScreen = () => (
  <Box sx={{ position: "fixed", zIndex: 1300, inset: 0, opacity: 1, backgroundColor: "white" }}>
    <Stack alignItems="center" justifyContent="center">
      <Box sx={{ mt: "30%", mb: 0.5 }}>
        <Image alt="Skipify" src={samsungLogo} width="140" />
      </Box>
      <Typography variant="large">Connected Wallet</Typography>
      <PoweredBySkipifyIcon sx={{ mt: 2, mr: -2 }} />
    </Stack>
  </Box>
);

export default SamsungDemoLoadingScreen;
