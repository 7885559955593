import { ButtonProps, CircularProgress } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const CircularLoading = () => (
  <CircularProgress
    size={24}
    sx={{
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-12px",
      marginLeft: "-12px",
    }}
  />
);

type LoadingButtonProps = ButtonProps & { loading?: boolean };

const LoadingButton = ({ loading, children, disabled, ...props }: LoadingButtonProps) => (
  <Button disabled={disabled || loading} {...props}>
    {!loading ? children : <CircularLoading />}
  </Button>
);

export default LoadingButton;
