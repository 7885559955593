import React, { FC, useState } from "react";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Stack, Typography } from "@mui/material";
import LoadingButton from "@components/LoadingButton";
import cardcover from "../../images/cardcover/cardcover.svg";
import Image from "next/image";
import { UnLinkedCard } from "@models/card";
import CardComponent from "../Card";
import { CardSize } from "../../payment-constants";
import skipifyEvents from "@services/skipifyEvents";

interface CardListProps {
  cardList: UnLinkedCard[];
  onContinue?: (value: UnLinkedCard) => void;
  disabled?: boolean;
  loading?: boolean;
}

// This is only intended to be used inside the Payment page where all cards are unlinked cards and therefore have a card_meta_data_id
const CardListComponent: FC<CardListProps> = ({
  cardList,
  onContinue = () => {
    // noop
  },
  disabled = false,
  loading = false,
}) => {
  const [internalSelectedCard, setInternalSelectedCard] = useState<UnLinkedCard>();
  const [expanded, setExpanded] = useState<boolean>(false);

  const onCardSelection = (card: UnLinkedCard) => {
    if (!disabled) {
      setInternalSelectedCard(card);
      skipifyEvents.setIssuer(card.network_type);
      skipifyEvents.track("fe_cl_clicked_card");
    }
  };
  const listItems = (cards: UnLinkedCard[]) =>
    cards.map((card) => (
      <Stack key={card.card_metadata_id}>
        <Stack
          direction="row"
          spacing={1}
          style={{
            opacity: 0.9,
            backgroundColor: "white",
            zIndex: 9,
            position: "absolute",
            display: internalSelectedCard?.card_metadata_id === card.card_metadata_id ? "block" : "none",
          }}
        >
          <Image
            data-testid={"card-cover-" + card.card_metadata_id}
            src={cardcover}
            width={180}
            alt={`Card selected ${internalSelectedCard?.description}`}
          />
          <LoadingButton
            data-testid={"card-continue-button-" + card.card_metadata_id}
            variant="contained"
            style={{
              float: "right",
              top: 40,
              display: internalSelectedCard?.card_metadata_id === card.card_metadata_id ? "block" : "none",
              backgroundColor: "black",
              width: "114px",
            }}
            onClick={() => internalSelectedCard && onContinue(internalSelectedCard)}
            className="card-button"
            disabled={disabled}
            loading={loading}
          >
            Continue
          </LoadingButton>
        </Stack>
        <Box data-testid={"card-" + card.card_metadata_id} onClick={() => onCardSelection(card)}>
          <CardComponent
            lastFour={card.last_four}
            description={card.description}
            cardArt={card.card_art_url}
            network={card.network_type}
            size={CardSize.Large}
          ></CardComponent>
        </Box>
      </Stack>
    ));

  const shownCards = expanded || cardList.length <= 3 ? cardList : cardList.slice(0, 3);

  return (
    <Box className="cardList-container">
      <Box mb={1}>
        <Typography variant="medium">Select the card you would like to use.</Typography>
      </Box>
      <Box className="card-list">
        <Stack spacing={2} divider={<Divider />}>
          {listItems(shownCards)}
        </Stack>
        <Divider sx={{ mt: 2 }} />
        <Box textAlign="center">
          {shownCards !== cardList && (
            <Button
              data-testid="show-all-button"
              variant="contained"
              style={{ top: 15, backgroundColor: "black" }}
              onClick={() => setExpanded(true)}
            >
              Show All
            </Button>
          )}
          {expanded && (
            <Button
              data-testid="show-less-button"
              variant="contained"
              style={{ top: 15, backgroundColor: "black" }}
              onClick={() => setExpanded(false)}
            >
              Show Less
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CardListComponent;
