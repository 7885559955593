import { datadogLogs } from "@datadog/browser-logs";
import getEnv from "@utils/getEnv";

import pjs from "../../../package.json";

datadogLogs.init({
  clientToken: getEnv().DD_CLIENT_TOKEN || "",
  site: "us5.datadoghq.com",
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  forwardConsoleLogs: ["warn", "error"],
  env: getEnv().ENV,
  service: "shakira",
  version: pjs.version,
});

export default datadogLogs;
