// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Offline as OfflineIntegration } from "@sentry/integrations";
import getEnv from "@utils/getEnv";

const BREADCRUMB_CATEGORIES = {
  FETCH: "fetch",
  XHR: "xhr",
  NAVIGATION: "navigation",
};

if (typeof window !== "undefined") {
  Sentry.addTracingExtensions();
  Sentry.init({
    dsn: getEnv().SENTRY_DSN,
    environment: getEnv().ENV,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          /^\//,
          /https:\/\/.*api\.skipify\.com/,
          /https:\/\/.*orders\.skipify\.com/,
          /https:\/\/.*merchants\.skipify\.com/,
          /https:\/\/.*callback\.skipify\.com/,
        ], //This controls when we set the trace header for distribute tracing
      }),
      new OfflineIntegration(),
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: Number.parseInt(getEnv().SENTRY_TRACING_RATE_VALUE || "0.5", 10),
    tracesSampleRate: Number.parseInt(getEnv().SENTRY_TRACING_RATE_VALUE || "0.5", 10),
    enabled: getEnv().SENTRY_FLAG === "enabled",
    beforeBreadcrumb(breadcrumb, _hint) {
      if (
        breadcrumb.category === BREADCRUMB_CATEGORIES.FETCH &&
        breadcrumb.data?.url.includes("https://api2.amplitude.com")
      ) {
        return null;
      }
      return breadcrumb;
    },
  });
} else {
  Sentry.init({
    dsn: getEnv().SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: Number.parseInt(getEnv().SENTRY_TRACING_RATE_VALUE || "0.5", 10),
    enabled: getEnv().SENTRY_FLAG === "enabled",
  });
}
