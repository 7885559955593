import { Skeleton, Stack } from "@mui/material";

function InitialLoadingSkeleton() {
  return (
    <Stack>
      <Skeleton variant="text" />
      <Skeleton variant="text" sx={{ mb: 1 }} width="50%" />
      <Skeleton height={60} />
      <Skeleton height={60} />
      <Skeleton variant="text" width="50%" />
    </Stack>
  );
}

export default InitialLoadingSkeleton;
