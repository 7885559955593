import axios, { AxiosError } from "axios";
import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";

import {
  ApiResponse,
  AuthorizeResponse,
  DestinationAlternative,
  LookupUserErrorStatus,
  LookupUserResponse,
  StepupType,
} from "@models/api/apiResponses";
import { AuthServiceUser } from "@models/authServiceUser";
import { Identity } from "@models/identity";
import { User } from "@models/user";
import skipifyEvents from "@services/skipifyEvents";
import {
  authorize,
  guestSignUp,
  lookupByFingerprint,
  lookupUser,
  lookupUserFull,
  promoteGuest,
  reAuthorize,
} from "@services/auth";
import { getUser, patchMe, setDefaultIdentityId } from "@services/user";
import { inDevTestLocal } from "@utils/inDevTestLocal";
import { LookupUserFullOptions } from "@models/api/apiRequests";

type AuthStore = {
  loading: boolean;
  hasBeenLookUpCalled: boolean;
  fetched: boolean;
  email: string;
  message: string;
  newUser: boolean;
  stepupType: StepupType | undefined;
  transactionId?: string;
  isPhoneRequired?: boolean;
  phonePrefilled?: boolean;
  ignorePrefilledPhone?: boolean;
  isGuest: boolean;
  explicitGuestLogin: boolean;
  phone?: string;
  maskedChannel?: string;
  maskedEmail?: string;
  maskedPhone?: string;
  destination: string;
  error: unknown;
  challengeAccepted: boolean;
  fetchUserError: boolean;
  eligible: boolean | undefined;
  returningDashboard: boolean | undefined;
  recognizeOnDevices: boolean | undefined;
  lookup: (
    merchantId: string | undefined,
    email: string | undefined,
    phone?: string,
    abortController?: AbortController,
    skipCardLinking?: boolean,
    usePrefilledPhone?: boolean,
  ) => Promise<LookupUserResponse | null>;
  lookupByDevice: (merchantId: string) => Promise<LookupUserResponse | null>;
  lookupFull: (options: LookupUserFullOptions) => Promise<LookupUserResponse | null>;
  authorize: () => Promise<AuthorizeResponse | null>;
  authorizeSuccess: boolean | null;
  authorizeErrorCode: number | null;
  lookupErrorStatus?: LookupUserErrorStatus;
  reAuthorize: (alternativeId?: string) => Promise<AuthorizeResponse | null | Error>;
  reset: () => void;
  resetIsPhoneRequired: () => void;
  setNewUser: (status: boolean) => void;
  setLoginData: (
    transactionId: string,
    email?: string,
    isPhoneRequired?: boolean,
    wasUserIdentifyByDevice?: boolean,
    phone?: string,
    phonePrefilled?: boolean,
    maskedPhone?: string,
  ) => void;
  guestLogin: (opts?: GuestLoginOptions) => Promise<void>;
  resetChallenge: (isPhoneRequired?: boolean, ignorePrefilledPhone?: boolean) => void;
  getUserProfile: (dashboard?: boolean) => Promise<User | undefined>;
  markAsReturningDashboardUser: () => Promise<void>;
  beginGuestPromotion: () => Promise<void>;
  user?: User; // Mapped model of a user
  rawUser?: User | AuthServiceUser; // Raw model from the response with no mapping
  canChangePhone: boolean;
  fromEmbeddedLogin?: boolean;
  setFromEmbeddedLogin: (fromEmbeddedLogin: boolean) => void;
  fromEnrollment?: boolean; // true if user is already tried to enroll
  setEnrollmentChallengeInitialization: (data: {
    email: string;
    transactionId: string;
    message: string;
    stepupType: StepupType;
    maskedChannel: string;
    destination: string;
    canChangePhone: boolean;
  }) => void;
  setPartialEnrollmentData: (data: { email: string; transactionId: string; isPhoneRequired: boolean }) => void;
  attemptsRemaining: number | null;
  setAttemptsRemaining: (n: number | null) => void;
  updateLoginMethod: (identityId: string) => Promise<AuthServiceUser | undefined>;
  updateDeviceRecognition: (value: boolean) => void;
  metadata?: object;
  destinationAlternatives?: DestinationAlternative[];
  setDestinationChannel: (payload: {
    destination: string;
    maskedChannel: string;
    eligible: boolean;
    maskedEmail?: string;
  }) => void;
  wasUserIdentifyByDevice: boolean;
  selectedCountry?: string;
  setSelectedCountry: (country: string) => void;
};

/**
 * Options to determine the behavior of the guest login action.
 * Explicit is true for users that explicitly requested a guest login, instead of implicit guest logins (e.g. unrecognized paylink users)
 */
export type GuestLoginOptions = Partial<{
  phone?: string;
  explicit: boolean;
}>;

// Helper function. Once we get rid of the core api user and all feature flags - we will need another round of refactoring
const convertAuthServiceUserToUser = (asu: AuthServiceUser): User => {
  const res = {
    uid: asu.id,
    phoneNumber: "",
    email: "",
    returningDashboard: asu.returningDashboard,
    recognizeOnDevices: asu.recognizeOnDevices,
    id: 0, // Not used
    firstName: asu.displayName, // Not used
    lastName: "", // Not used
    is_guest: false, // Not used
    role: "", // Not used
    created_at: "", // Not used
    updated_at: "", // Not used
  };

  skipifyEvents.setCustomerId(asu.id);

  const emails: Identity[] = [];
  const phones: Identity[] = [];
  asu.identities.forEach((i) => {
    let arr;
    if (i.type === "email") {
      arr = emails;
    } else if (i.type === "phone") {
      arr = phones;
    } else {
      return;
    }

    if (i.verified) {
      arr.unshift(i);
    } else {
      arr.push(i);
    }
  });

  if (emails.length) {
    res.email = emails[0].value;
  }
  if (phones.length) {
    res.phoneNumber = phones[0].value;
  }

  return res;
};

const authStore: StateCreator<AuthStore, [["zustand/devtools", AuthStore]]> = (set, getState) => ({
  loading: false,
  hasBeenLookUpCalled: false,
  fetched: false,
  lookupErrorStatus: undefined,
  authorizeSuccess: null,
  authorizeErrorCode: null,
  email: "",
  message: "",
  maskedChannel: "",
  maskedEmail: "",
  maskedPhone: "",
  destination: "",
  transactionId: undefined,
  stepupType: undefined,
  isPhoneRequired: undefined,
  phonePrefilled: undefined,
  ignorePrefilledPhone: undefined,
  error: undefined,
  fetchUserError: false,
  returningDashboard: undefined,
  recognizeOnDevices: undefined,
  user: undefined,
  canChangePhone: true,
  isGuest: false,
  explicitGuestLogin: false,
  attemptsRemaining: null,
  eligible: undefined,
  challengeAccepted: false,
  metadata: undefined,
  destinationAlternatives: undefined,
  newUser: false,
  wasUserIdentifyByDevice: false,
  selectedCountry: undefined,

  async lookup(
    merchantId?: string,
    email?: string,
    phone?: string,
    abortController?: AbortController,
    skipCardLinking?: boolean,
    usePrefilledPhone?: boolean,
  ) {
    const transactionId = getState().transactionId;

    if (phone) {
      set({ phone }, false, "lookupSetPhone");
    }
    if (email) {
      set({ email }, false, "lookupSetEmail");
    }

    try {
      const lookupResponse = await lookupUser(
        merchantId,
        email,
        phone,
        phone || usePrefilledPhone ? transactionId : undefined,
        abortController,
        skipCardLinking,
        usePrefilledPhone,
      );
      if (lookupResponse.data) {
        set(
          {
            eligible: lookupResponse.data.eligible,
            message: lookupResponse.message,
            transactionId: lookupResponse.data.transactionId,
            attemptsRemaining: null,
            destination: lookupResponse.data.destination || undefined,
            maskedChannel: lookupResponse.data.maskedChannel || undefined,
            maskedEmail: lookupResponse.data.maskedEmail || undefined,
            maskedPhone: lookupResponse.data.maskedPhone || undefined,
            error: undefined,
            lookupErrorStatus: undefined,
            ...("isPhoneRequired" in lookupResponse.data
              ? { isPhoneRequired: lookupResponse.data.isPhoneRequired }
              : {}),
            ...("phonePrefilled" in lookupResponse.data ? { phonePrefilled: lookupResponse.data.phonePrefilled } : {}),
          },
          false,
          "lookup",
        );
        skipifyEvents.trackLookupUser(email, lookupResponse.data.isPhoneRequired);

        if (lookupResponse.data.isPhoneRequired) {
          skipifyEvents.track("fe_pre_otp_phone_requested");
        }
        // Did we just make a lookup by email
        if (email && !phone) {
          // User can change a phone if phone is required by the email initial lookup
          set(
            { canChangePhone: lookupResponse.data.isPhoneRequired || lookupResponse.data.phonePrefilled },
            false,
            "lookupSetCanChangePhone",
          );
        }
        return lookupResponse.data;
      }
      return null;
    } catch (error) {
      if (!axios.isCancel(error)) {
        set({ error }, false, "lookupError");
        const response = (error as AxiosError<ApiResponse<LookupUserResponse>>).response;
        // When status is 404 or errorStatus is PHONE_LIMIT_REACHED we need to reset auth state. As of April 2024, only max phone limit sends error status from lookup
        if (
          response?.status === 404 ||
          response?.data.data?.errorStatus === LookupUserErrorStatus.PHONE_LIMIT_REACHED
        ) {
          set(
            {
              canChangePhone: true,
              isPhoneRequired: true,
              transactionId: response?.data?.data?.transactionId,
              attemptsRemaining: null,
            },
            false,
            "lookup404",
          );
        }

        if (response?.data.data?.errorStatus) {
          set(
            {
              lookupErrorStatus: LookupUserErrorStatus[response.data.data.errorStatus] || undefined,
            },
            false,
            "lookupErrorStatus",
          );
        }
      }
      return null;
    }
  },
  async lookupByDevice(merchantId: string) {
    try {
      const lookupDataRequest = await lookupByFingerprint(merchantId);

      if (lookupDataRequest.data) {
        if (lookupDataRequest.data.eligible) {
          skipifyEvents.track("fe_customer_device_recognized");
        }
        set(
          {
            destination: lookupDataRequest.data.destination || undefined,
            maskedChannel: lookupDataRequest.data.maskedChannel || undefined,
            eligible: lookupDataRequest.data.eligible,
            maskedEmail: lookupDataRequest.data.maskedEmail,
            transactionId: lookupDataRequest.data.transactionId,
            isPhoneRequired: lookupDataRequest.data.isPhoneRequired,
            canChangePhone: lookupDataRequest.data.isPhoneRequired,
            wasUserIdentifyByDevice: true,
            hasBeenLookUpCalled: true,
          },
          false,
          "lookupByDevice",
        );
        return lookupDataRequest.data;
      }
      set({ hasBeenLookUpCalled: true }, false, "SET_LOOKUP_CALLED");
      return null;
    } catch (e) {
      set({ hasBeenLookUpCalled: true }, false, "SET_LOOKUP_CALLED");
      if (axios.isAxiosError(e)) {
        // 404 is a normal behaviour for user not found
        if (e.response?.status === 404) {
          skipifyEvents.track("fe_customer_device_not_recognized");
          return null;
        }
      }

      if (e instanceof Error) {
        console.error(`Something went wrong while looking up by fingerprint`, e);
      }
      throw e;
    }
  },
  async lookupFull({ merchantId, email, phone, transactionId, skipCardLinking, abortController }) {
    try {
      const resp = await lookupUserFull({ merchantId, email, phone, transactionId, skipCardLinking, abortController });
      if (resp.data) {
        set(
          {
            transactionId: resp.data.transactionId,
            eligible: resp.data.eligible,
            destination: resp.data.destination,
          },
          false,
          "lookupFull",
        );
        return resp.data;
      }
      return null;
    } catch (err) {
      console.warn("authStore: error ", err);
      return null;
    }
  },
  setLoginData(
    transactionId: string,
    email?: string,
    isPhoneRequired?: boolean,
    wasUserIdentifyByDevice = false,
    phone?: string,
    phonePrefilled?: boolean,
    maskedPhone?: string,
  ) {
    if (isPhoneRequired === false) {
      set({ canChangePhone: false }, false, "setLoginDataNoPhoneRequired");
    }
    set(
      { transactionId, email, isPhoneRequired, wasUserIdentifyByDevice, phone, phonePrefilled, maskedPhone },
      false,
      "setLoginData",
    );
  },
  async authorize() {
    const transactionId = getState().transactionId;
    const authorizeSuccess = getState().authorizeSuccess;
    if (!transactionId) {
      set({ error: "Challenge is not found" }, false, "authorizeNoTransactionIdFound");
      return null;
    }
    set({ loading: true }, false, "SET_AUTHORIZE_LOADING");
    try {
      const authorizeResponse = await authorize(transactionId);
      set({ authorizeSuccess: true }, false, "authorizeSuccess");
      if (authorizeResponse.data) {
        const {
          message,
          stepup_type: stepupType,
          maskedChannel,
          destination,
          metadata,
          destinationAlternatives,
        } = authorizeResponse.data;

        skipifyEvents.track("fe_otp_sent");

        set(
          {
            message,
            stepupType,
            maskedChannel,
            destination,
            attemptsRemaining: null,
            challengeAccepted: true,
            metadata,
            destinationAlternatives,
          },
          false,
          "authorize",
        );
        return authorizeResponse.data;
      }
      return null;
    } catch (error) {
      // If we had an error before authorizeSuccess would be false, set here.
      // If we succeeded previously, and by some chance this was double called (i.e. local dev env) only set failure if no success before
      set({ error, authorizeSuccess: !!authorizeSuccess }, false, "authorizeError");
      if (axios.isAxiosError(error)) {
        set({ authorizeErrorCode: error?.response?.status });
      }
      return null;
    } finally {
      set({ loading: false }, false, "SET_AUTHORIZE_LOADING");
    }
  },
  async guestLogin(opts?: GuestLoginOptions) {
    const email = getState().email;
    // TODO[Jesus][IDCH-151]: we should get the email before of avoid the requirement of an email
    if (!email) {
      throw new Error("Email is not set");
    }
    let phone = opts?.phone;
    if (phone) {
      set({ phone }, false, "guestLoginSetPhone");
    } else {
      phone = getState().phone;
    }
    await guestSignUp(email, phone);
    set({ isGuest: true, explicitGuestLogin: Boolean(opts?.explicit) }, false, "guestLogin");
  },
  async reAuthorize(alternativeId?: string) {
    // Clear authorize error code in case of reAuthorize at enroll
    set({ authorizeErrorCode: null });
    const transactionId = getState().transactionId;

    if (!transactionId) {
      set({ error: "Challenge is not found" }, false, "reAuthorizeNoTransactionIdFound");
      return null;
    }

    try {
      const authorizeResponse = await reAuthorize(transactionId, alternativeId);
      if (authorizeResponse.data) {
        const {
          message,
          stepup_type: stepupType,
          maskedChannel,
          destination,
          metadata,
          destinationAlternatives,
        } = authorizeResponse.data;

        set(
          { message, stepupType, maskedChannel, destination, metadata, destinationAlternatives },
          false,
          "reAuthorize",
        );

        return authorizeResponse.data;
      }

      return null;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        set({ authorizeErrorCode: error?.response?.status });
      }
      set({ error }, false, "reAuthorizeError");
      return error as Error;
    }
  },
  async getUserProfile(dashboard?: boolean) {
    try {
      const response = await getUser();
      // Abnormal behavior
      if (!response.data) {
        console.error("[authStore][getUserProfile] empty response data");
        set({ fetchUserError: true });
        return;
      }
      let tmpUser;
      // Response is from the auth service
      if ("identities" in response.data) {
        const user = convertAuthServiceUserToUser(response.data);
        tmpUser = user;
        set(
          { email: user.email, user, rawUser: response.data, recognizeOnDevices: user.recognizeOnDevices },
          false,
          "getUserProfile",
        );
      } else {
        set({ email: response.data.email, user: response.data, rawUser: response.data }, false, "getUserProfile");
      }
      set({ fetchUserError: false });
      return tmpUser;
    } catch (e) {
      if (!dashboard) getState().resetChallenge();
      set({ fetchUserError: true });
    }
  },
  async beginGuestPromotion() {
    set({ fromEnrollment: true }, false, "beginGuestPromotionStart");
    try {
      const lookupData = await promoteGuest();
      if (!lookupData.data) {
        return;
      }

      const {
        message,
        data: { transactionId },
      } = lookupData;

      set(
        {
          message,
          transactionId,
          canChangePhone: false,
        },
        false,
        "beginGuestPromotion",
      );
    } catch (e) {
      set({ error: e }, false, "beginGuestPromotionError");
      console.error(e);
      throw new Error("Enrollment failed.");
    }
  },
  setDestinationChannel({ destination, maskedChannel, eligible, maskedEmail }) {
    set(
      {
        destination,
        maskedChannel,
        eligible,
        maskedEmail,
      },
      false,
      "SET_DESTINATION_CHANNEL",
    );
  },
  reset() {
    set(
      () => {
        return {
          email: "",
          phone: "",
          message: "",
          maskedChannel: "",
          maskedEmail: "",
          maskedPhone: "",
          phonePrefilled: undefined,
          destination: "",
          transactionId: undefined,
          stepupType: undefined,
          isPhoneRequired: undefined,
          error: undefined,
          fromEnrollment: undefined,
          fromEmbeddedLogin: undefined,
          attemptsRemaining: null,
          user: undefined,
          challengeAccepted: false,
          metadata: undefined,
          destinationAlternatives: undefined,
          authorizeSuccess: null,
          wasUserIdentifyByDevice: false,
          ignorePrefilledPhone: false,
        };
      },
      false,
      "reset",
    );
  },
  resetIsPhoneRequired() {
    set({ isPhoneRequired: undefined }, false, "resetIsPhoneRequired");
  },
  resetChallenge(isPhoneRequired, ignorePrefilledPhone) {
    set(
      {
        authorizeSuccess: null,
        transactionId: undefined,
        stepupType: undefined,
        isPhoneRequired,
        error: undefined,
        message: "",
        user: undefined,
        fromEnrollment: undefined,
        attemptsRemaining: null,
        challengeAccepted: false,
        metadata: undefined,
        destinationAlternatives: undefined,
        wasUserIdentifyByDevice: false,
        maskedEmail: "",
        maskedPhone: "",
        phonePrefilled: undefined,
        ignorePrefilledPhone,
      },
      false,
      "resetChallenge",
    );
  },
  setFromEmbeddedLogin(fromEmbeddedLogin: boolean) {
    set({ fromEmbeddedLogin }, false, "setFromEmbeddedLogin");
  },
  fromEnrollment: undefined,
  setPartialEnrollmentData(data: { email: string; transactionId: string; isPhoneRequired: boolean }) {
    // Used in enroll method to set partial data, when we don't have a phone number yet
    set({ ...data, fromEnrollment: true }, false, "setPartialEnrollmentData");
  },
  setEnrollmentChallengeInitialization(data) {
    set(data, false, "setEnrollmentChallengeInitialization");
  },
  setAttemptsRemaining(data: null | number) {
    set({ attemptsRemaining: data }, false, "setAttemptsRemaining");
  },
  async updateLoginMethod(identityId: string): Promise<AuthServiceUser | undefined> {
    let res: ApiResponse<AuthServiceUser>;
    const identities = (getState().rawUser as AuthServiceUser)?.identities;
    const oldDefault = identities?.find((identity) => identity.default);
    const newIdentity = identities?.find((identity) => identity.id === identityId);
    try {
      res = await setDefaultIdentityId(identityId);
      skipifyEvents.track("fe_set_default_auth", {
        old_type: oldDefault?.type,
        type: newIdentity?.type,
      });
    } catch (e) {
      console.error("[authStore] failed to set a default identity id");
      return;
    }

    if (!res?.data) {
      return;
    }

    const user = convertAuthServiceUserToUser(res.data);
    set({ email: user.email, user, rawUser: res.data }, false, "getUserProfile");

    return res.data;
  },
  updateDeviceRecognition: (value) => set(() => ({ recognizeOnDevices: value })),
  updateChallengeAccepted: (challengeAccepted: boolean) => set(() => ({ challengeAccepted })),
  markAsReturningDashboardUser: async () => {
    await patchMe({ returningDashboard: true });
    set({ returningDashboard: true });
  },
  setNewUser: (status: boolean) => {
    set({ newUser: status });
  },
  setSelectedCountry: (country) => {
    set({ selectedCountry: country });
  },
});

const useAuthStore = create<AuthStore>()(devtools(authStore, { enabled: inDevTestLocal, name: "authStore" }));

export default useAuthStore;
