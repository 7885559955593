import { Order } from "@models/order";
import { AccordionProps, Divider, Stack } from "@mui/material";
import OrderTotal from "./OrderTotal";
import PayLinkMemoDescription from "./PayLinkMemoDescription";
import ProductList from "./ProductList";

type Props = {
  order: Order;
  nonHeaderDisplay?: boolean;
  quantityEditable?: boolean;
  showRemove?: boolean;
} & Partial<Omit<AccordionProps, "order">>;
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { Cart } from "@components/Header";
import { useState } from "react";

export default function OrderSummaryAccordion({
  order,
  nonHeaderDisplay,
  quantityEditable,
  showRemove,
  ...accordionProps
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      sx={{ backgroundColor: "#F3F3F3", px: 2 }}
      {...accordionProps}
    >
      <AccordionSummary
        expandIcon={<Cart open={expanded} order={order} />}
        sx={{
          height: 64,
          alignItems: "center",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(0)",
          },
        }}
        aria-controls="order-summary-content"
        id="order-summary-header"
      >
        <Typography variant="medium" fontWeight="bold">
          Order summary
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2} py={2}>
          <ProductList
            nonHeaderDisplay={nonHeaderDisplay}
            order={order}
            items={order.items}
            quantityEditable={quantityEditable}
            showRemove={showRemove}
          />
          {(order.memo || order.description) && (
            <PayLinkMemoDescription memo={order.memo} description={order.description} />
          )}
          <Divider />
          <OrderTotal order={order} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
