import axios from "axios";

import { MerchantConfig } from "@models/merchantConfig";
import axiosRetry from "@services/utils/axiosRetry";
import { addSentryInterceptors } from "./utils/interceptSentry";
import interceptWithErrorLogs from "@services/utils/interceptWithErrorLogs";
import getEnv from "@utils/getEnv";

const BASE_URL = getEnv().MERCHANT_SERVICE_ROOT;
const API_VERSION = "v1";
const API_MODULE = "merchants";

const merchantApi = axios.create({
  baseURL: `${BASE_URL}/${API_VERSION}/${API_MODULE}`,
});
merchantApi.interceptors.response.use(undefined, interceptWithErrorLogs);
addSentryInterceptors(merchantApi);

axiosRetry(merchantApi);

export async function getMerchantConfig(merchantId: string): Promise<MerchantConfig> {
  const response = await merchantApi.get<MerchantConfig>(`/${merchantId}/public`);
  return response.data;
}
