import { ComponentOptions } from "../@types/util";

const MuiCircularProgress: ComponentOptions<"MuiCircularProgress"> = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.brand.dark,
    }),
  },
};

const components = { MuiCircularProgress };
export default components;
