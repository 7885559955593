const LOCALSTORAGE_SETTING_KEYS = {
  enrollmentData: "skipify_enrollment_data",
  returningUserData: "skipify_returning_user_data",
  lookupData: "skipify_lookup_data",
  forceFingerprintjs: "skipify_force_fpjs",
  forceRUM: "skipify_force_rum",
  dashboardSession: "dashboard_session",
};

export { LOCALSTORAGE_SETTING_KEYS };
