import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";

type UnrecoverableErrorProps = {
  loadingError: string;
};

// Placeholder for unrecoverable error component
// TODO: Take in an error and report through sentry
function UnrecoverableError({ loadingError }: UnrecoverableErrorProps) {
  useEffect(() => {
    console.error("[UnrecoverableError]", loadingError);
  }, [loadingError]);

  return (
    <Stack>
      <Typography
        variant="medium"
        sx={{
          marginTop: "8.5px",
        }}
      >
        Oh no, there has been a problem
      </Typography>
    </Stack>
  );
}

export default UnrecoverableError;
