import { Chip, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { CardSize, defaultCardArtFilename, STATIC_CARD_PREFIX } from "../../payment-constants";
import { FC, useEffect, useState } from "react";
import { Warning } from "@mui/icons-material";
import { useFlags } from "@hooks/ldWithOverrides";

export interface CardProps {
  size?: CardSize;
  isDefault?: boolean;
  cardName?: string;
  cardArt?: string;
  lastFour?: string;
  network?: string;
  description?: string;
  disabled?: boolean;
  cardCarousel?: boolean;
}

function getWidth(size: CardSize) {
  switch (size) {
    case CardSize.Large: {
      return 180;
    }
    case CardSize.Medium: {
      return 67;
    }
    case CardSize.Small: {
      return 38;
    }
    default: {
      return 180;
    }
  }
}
function getHeight(size: CardSize) {
  switch (size) {
    case CardSize.Large: {
      return 114;
    }
    case CardSize.Medium: {
      return 42;
    }
    case CardSize.Small: {
      return 24;
    }
    default: {
      return 114;
    }
  }
}

// This component provides the collapsed card for V1 wallet and for carousel. Additionally it provides the cards for the v1 expanded Wallet view.
const CardComponent: FC<CardProps> = ({
  size,
  isDefault,
  cardName,
  cardArt,
  lastFour,
  network,
  description,
  disabled = false,
  cardCarousel = true,
}) => {
  const cardFallback =
    STATIC_CARD_PREFIX +
    (defaultCardArtFilename.get(network || "") ? "wallet_row/" : "") +
    (defaultCardArtFilename.get(network || "") ?? "generic.svg");

  const [art, setArt] = useState(cardArt || cardFallback);
  if (size === undefined) {
    size = CardSize.Large;
  }
  useEffect(() => {
    if (cardArt) {
      setArt(cardArt);
    } else setArt(cardFallback);
  }, [cardArt, cardFallback]);

  const flags = useFlags<{ walletCarouselEnabled: boolean }>();

  const width = getWidth(size);
  const height = getHeight(size);
  const fallBackName = "Credit Card";

  const lastFourPrefix = flags.walletCarouselEnabled ? "••••" : "- ";
  return (
    <Stack
      direction={size === CardSize.Large ? "column" : "row"}
      spacing={1}
      alignItems={!(size === CardSize.Small) && !flags.walletCarouselEnabled ? "flex-start" : "center"}
    >
      <Image
        unoptimized={!!cardArt}
        data-testid="card-art"
        src={art}
        width={width}
        height={height}
        alt={`${cardName || description || fallBackName} - ${lastFour}`}
        onError={() => {
          setArt(cardFallback);
        }}
        style={{
          ...(disabled && { opacity: 0.6 }),
        }}
      />
      <Stack
        alignItems="flex-start"
        spacing={0.5}
        sx={{ ...(disabled && { color: (theme) => theme.palette.text.secondary }) }}
      >
        <Typography color="inherit" variant="medium">
          {size !== CardSize.Small && !cardCarousel && (
            <Typography variant="medium" component="span" fontWeight={size === CardSize.Large ? "medium" : "bold"}>
              {cardName || description || fallBackName}{" "}
            </Typography>
          )}
          {lastFourPrefix}
          {lastFour}
          {disabled && (
            <>
              {" "}
              <Warning fontSize="inherit" color="warning" />
            </>
          )}
        </Typography>
        {isDefault && <Chip label="Default" size="small" />}
      </Stack>
    </Stack>
  );
};
export default CardComponent;
