import { ComponentOptions } from "../@types/util";

const MuiSwitch: ComponentOptions<"MuiSwitch"> = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      width: 44,
      height: 24,
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        "&.Mui-checked": {
          transform: "translateX(20px)",
          color: theme.palette.text.white,
          "&+.MuiSwitch-track": {
            backgroundColor: theme.palette.brand.main, // track color when checked
            opacity: 1,
          },
        },
      },
      "& .MuiSwitch-thumb": {
        // general thumb style
        boxSizing: "border-box",
        width: 20,
        height: 20,
      },
      "& .MuiSwitch-track": {
        // general track style
        borderRadius: 12,
        backgroundColor: theme.palette.secondary.light,
        opacity: 1,
        transition: "background-color 500ms",
      },
      "&:hover .Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.brand.main, // hover track color when checked
      },
      "&:hover .MuiSwitch-track": {
        backgroundColor: theme.palette.text.disabled, // hover track color when not checked
      },
    }),
  },
  variants: [
    {
      props: { size: "small" },
      style: {
        width: 36,
        height: 20,
        "& .MuiSwitch-switchBase": {
          "&.Mui-checked": {
            transform: "translateX(16px)",
          },
        },
        "& .MuiSwitch-thumb": {
          // general thumb style
          width: 16,
          height: 16,
        },
      },
    },
  ],
};

const components = { MuiSwitch };
export default components;
