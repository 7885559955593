import { ComponentOptions } from "../@types/util";
import { fontWeights } from "../base/typography";

const MuiChip: ComponentOptions<"MuiChip"> = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.secondary,
      background: theme.palette.brand.light,
      fontSize: 10,
      fontWeight: fontWeights.extraBold,
    }),
  },
};

const components = { MuiChip };
export default components;
