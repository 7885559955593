import { Destination } from "@models/api/apiResponses";

export default function getMaskedChannel(maskedChannel: string, destination: Destination): string {
  if (!maskedChannel) {
    return "";
  }

  switch (destination) {
    case Destination.EMAIL:
      return maskedChannel;
    case Destination.PHONE:
      return `(•••) •••-${maskedChannel}`;
    case Destination.WEBAUTHN:
      return "passkey";
    default:
      return "";
  }
}
