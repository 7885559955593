import skipifyEvents from "@services/skipifyEvents";
import { InternalAxiosRequestConfig } from "axios";

const FLOW_TYPE_HEADER = "x-flow-type";
const SESSION_ID_HEADER = "x-amplitude-session-id";

const interceptWithSkipifyHeaders = async (requestConfig: InternalAxiosRequestConfig) => {
  if (skipifyEvents.flowType) {
    requestConfig.headers[FLOW_TYPE_HEADER] = skipifyEvents.flowType;
  }
  const sessionId = skipifyEvents.getSessionId();
  if (sessionId) {
    requestConfig.headers[SESSION_ID_HEADER] = sessionId;
  }

  return requestConfig;
};

export default interceptWithSkipifyHeaders;
