import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "@services/utils/axiosRetry";

import { Address } from "@models/address";
import { ConfirmCardsRequest } from "@models/api/apiRequests";
import {
  AllCardsMethodsResponse,
  ApiResponse,
  CompleteCardResponse,
  ConfirmCardResponse,
  CreatePaymentMethodResponse,
  UpdatePaymentMethodResponse,
} from "@models/api/apiResponses";
import { NewCard } from "@models/card";

import interceptUnauthorized from "@services/utils/interceptUnauthorized";
import interceptWithFingerprint from "@services/utils/interceptWithFingerprint";
import transformRequestWithAuthorization from "@services/utils/transformRequestWithAuthorization";
import interceptWithErrorLogs from "@services/utils/interceptWithErrorLogs";
import interceptWithSkipifyHeaders from "@services/utils/interceptWithSkipifyHeaders";
import getEnv from "@utils/getEnv";

const BASE_URL = getEnv().API_ROOT;
const API_VERSION = "v3";

const MERCHANT_ID_HEADER = "x-merchant-id";

const paymentApiV3 = axios.create({
  baseURL: `${BASE_URL}/${API_VERSION}`,
  withCredentials: true,
  transformRequest: [transformRequestWithAuthorization],
});

paymentApiV3.interceptors.response.use(undefined, interceptUnauthorized);
paymentApiV3.interceptors.response.use(undefined, interceptWithErrorLogs);
paymentApiV3.interceptors.request.use(interceptWithFingerprint);
paymentApiV3.interceptors.request.use(interceptWithSkipifyHeaders);
axiosRetry(paymentApiV3);

export async function updatePaymentMethod(
  uid: string,
  payload: { billing_address_id?: string; billing_address?: Omit<Address, "address_uid">; card_nickname?: string },
  merchantId?: string,
): Promise<ApiResponse<UpdatePaymentMethodResponse>> {
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...(merchantId ? { [MERCHANT_ID_HEADER]: merchantId } : {}),
    },
  };

  const response = await paymentApiV3.put<ApiResponse<UpdatePaymentMethodResponse>>(`/cards/${uid}`, payload, config);
  return response.data;
}

export async function setDefaultPaymentMethod(uid: string, merchantId?: string): Promise<ApiResponse<null>> {
  const config: AxiosRequestConfig = {
    headers: {
      ...(merchantId ? { [MERCHANT_ID_HEADER]: merchantId } : {}),
    },
  };
  const response = await paymentApiV3.put<ApiResponse<null>>(`/cards/default/${uid}`, config);
  return response.data;
}

export async function deletePaymentMethod(uid: string, merchantId?: string): Promise<ApiResponse<null>> {
  const config: AxiosRequestConfig = {
    headers: {
      ...(merchantId ? { [MERCHANT_ID_HEADER]: merchantId } : {}),
    },
  };

  const response = await paymentApiV3.delete<ApiResponse<null>>(`/cards/${uid}`, config);
  return response.data;
}

export async function createPaymentMethod(
  payload: NewCard,
  merchantId?: string,
): Promise<ApiResponse<CreatePaymentMethodResponse>> {
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      ...(merchantId ? { [MERCHANT_ID_HEADER]: merchantId } : {}),
    },
  };

  const response = await paymentApiV3.post<ApiResponse<CreatePaymentMethodResponse>>(`/cards`, payload, config);
  return response.data;
}

const CARD_LINKING_API_MODULE = "card_provisioning";

const cardLinkingApiV3 = axios.create({
  baseURL: `${BASE_URL}/${API_VERSION}/${CARD_LINKING_API_MODULE}`,
  withCredentials: true,
  transformRequest: [transformRequestWithAuthorization],
});

cardLinkingApiV3.interceptors.response.use(undefined, interceptUnauthorized);
cardLinkingApiV3.interceptors.response.use(undefined, interceptWithErrorLogs);
cardLinkingApiV3.interceptors.request.use(interceptWithFingerprint);
cardLinkingApiV3.interceptors.request.use(interceptWithSkipifyHeaders);

axiosRetry(cardLinkingApiV3);

/**
 * Attempts to select a card to link from the issuer.
 *
 * @param panId id of the card being selected
 * @param issuerId id of the card issuer
 * @param orderId id of the order the user is selecting the card for
 * @returns @see ConfirmCardsResponse
 */
export async function confirmCardLink(
  panId: string,
  issuerId: string,
  orderId: string,
): Promise<ApiResponse<ConfirmCardResponse>> {
  const payload: ConfirmCardsRequest = issuerId
    ? {
        card_metadata_id: panId,
        issuer_id: issuerId,
        order_id: orderId,
      }
    : {
        card_id: panId,
        order_id: orderId,
      };

  const response = await cardLinkingApiV3.post<ApiResponse<ConfirmCardResponse>>("/confirm_pan", payload, {
    headers: { "Content-Type": "application/json" },
  });
  return response.data;
}

export async function completeCardLink(): Promise<ApiResponse<CompleteCardResponse>> {
  const response = await cardLinkingApiV3.post<ApiResponse<CompleteCardResponse>>("/complete_card", {});
  return response.data;
}

// SECTION for new public card linking api
const PUBLIC_CL_BASE_URL = getEnv().CARD_LINKING_SERVICE_ROOT;
const PUBLIC_CL_API_VERSION = "v1";

const publicCardLinkingApiV3 = axios.create({
  baseURL: `${PUBLIC_CL_BASE_URL}/${PUBLIC_CL_API_VERSION}`,
  withCredentials: true,
  transformRequest: [transformRequestWithAuthorization],
});

publicCardLinkingApiV3.interceptors.response.use(undefined, interceptUnauthorized);
publicCardLinkingApiV3.interceptors.response.use(undefined, interceptWithErrorLogs);
publicCardLinkingApiV3.interceptors.request.use(interceptWithFingerprint);
publicCardLinkingApiV3.interceptors.request.use(interceptWithSkipifyHeaders);

axiosRetry(publicCardLinkingApiV3);

export async function getAllCards(merchantId?: string): Promise<ApiResponse<AllCardsMethodsResponse>> {
  const response = await publicCardLinkingApiV3.get<ApiResponse<AllCardsMethodsResponse>>("/all_cards", {
    params: { by_merchant: merchantId },
  });
  // TODO add validation
  return response.data;
}
