import { Order } from "@models/order";
import { Stack } from "@mui/material";
import { OrderCartItem } from "skipify-types";
import Product from "./Product";

type Props = {
  items: OrderCartItem[];
  nonHeaderDisplay?: boolean;
  order: Order;
  quantityEditable?: boolean;
  showRemove?: boolean;
};

export default function ProductList({ items, nonHeaderDisplay, order, quantityEditable, showRemove }: Props) {
  return (
    <Stack spacing={2}>
      {items.map((item) => (
        <Product
          item={item}
          nonHeaderDisplay={nonHeaderDisplay}
          key={item.id}
          order={order}
          quantityEditable={quantityEditable}
          showRemove={showRemove}
        />
      ))}
    </Stack>
  );
}
