import { EventPropertiesMap, EventType, IdentifyProperties } from "@models/skipifyEvents";
import { FlowTypes } from "@constants/skipifyEvent";
import { Order } from "@models/order";
import { Pubsub } from "./pubsub";

export class SkipifyEvents {
  private pubSubService?: Pubsub;
  private isInitialized: boolean = false;

  async enableServices() {
    this.isInitialized = false;

    try {
      this.pubSubService = new Pubsub();
      this.isInitialized = true;
    } catch (err) {
      // timeout or initialization failed
      console.error(err);
    }
  }

  get isLoaded(): boolean {
    if (!this.isInitialized) return false;
    if (this.pubSubService) {
      return this.pubSubService.isLoaded;
    }
    return true;
  }

  get flowType(): FlowTypes | undefined {
    if (this.pubSubService) {
      return this.pubSubService.flowType;
    }
  }
  set flowType(value: FlowTypes | undefined) {
    if (this.pubSubService) {
      this.pubSubService.flowType = value;
    }
  }

  getSessionId(): number | undefined {
    return this.pubSubService?.sessionId;
  }

  setSessionId(id: number) {
    if (this.pubSubService) this.pubSubService.sessionId = id;
  }

  setFlowType(flow: FlowTypes) {
    this.pubSubService?.setFlowType(flow);
  }

  setIssuer(issuer?: string) {
    this.pubSubService?.setIssuer(issuer);
  }

  setCustomerId(customerId: string) {
    this.pubSubService?.setCustomerId(customerId);
  }

  setMerchantProperties(merchantId?: string, merchantName?: string, industry?: string, parentMerchant?: string) {
    this.pubSubService?.setMerchantProperties(merchantId, merchantName, industry, parentMerchant);
  }

  setOrderProperties(order: Order) {
    this.pubSubService?.setOrderProperties(order);
  }

  async loadWithFingerprint() {
    this.pubSubService?.loadWithFingerprint();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   */
  identify(userId?: string, properties?: IdentifyProperties) {
    this.pubSubService?.identify(userId, properties);
  }

  track<T extends EventType>(type: T, event_properties?: EventPropertiesMap[T]) {
    this.pubSubService?.track(type, event_properties);
  }

  trackLookupUser(email?: string, isPhoneRequired?: boolean) {
    this.pubSubService?.trackLookupUser(email, isPhoneRequired);
  }

  refresh() {
    this.pubSubService?.refresh();
  }

  async onExit() {
    await this.pubSubService?.onExit();
  }
}

const skipifyEvents = new SkipifyEvents();

export default skipifyEvents;
