import { datadogRum } from "@datadog/browser-rum";
import { getLocalStorageValue } from "@hooks/useLocalStorage";
import { LOCALSTORAGE_SETTING_KEYS } from "@constants/localStorage";
import getEnv from "@utils/getEnv";

import pjs from "../../../package.json";

const force = getLocalStorageValue(LOCALSTORAGE_SETTING_KEYS.forceRUM, "false");
if (["stage", "prod", "staging"].includes(getEnv().ENV || "") || `${force}` === "true") {
  datadogRum.init({
    applicationId: getEnv().DD_APP_ID || "",
    clientToken: getEnv().DD_CLIENT_TOKEN || "",
    site: "us5.datadoghq.com",
    service: "shakira",
    env: getEnv().ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: pjs.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: getEnv().ENV === "prod" ? "mask" : "allow", // Set to 'mask' to not expose user's addresses and any other non-input data
    useCrossSiteSessionCookie: true,
    useSecureSessionCookie: true,
    allowFallbackToLocalStorage: true,
    enableExperimentalFeatures: ["feature_flags"],
    allowedTracingUrls: [
      { match: /https:\/\/.*api\.skipify\.com/, propagatorTypes: ["tracecontext"] },
      { match: /https:\/\/.*shoppers\.skipify\.com/, propagatorTypes: ["tracecontext"] },
      { match: /https:\/\/.*orders\.skipify\.com/, propagatorTypes: ["tracecontext"] },
      { match: /https:\/\/.*merchants\.skipify\.com/, propagatorTypes: ["tracecontext"] },
      { match: /https:\/\/.*callback\.skipify\.com/, propagatorTypes: ["tracecontext"] },
    ],
  });
}
