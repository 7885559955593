import { ComponentOptions } from "../@types/util";

const MuiTextField: ComponentOptions<"MuiTextField"> = {
  styleOverrides: {
    root: ({ theme }) => ({
      "& .MuiInputLabel-root": {
        fontSize: theme.typography.medium.fontSize,
        "&.Mui-disabled": {
          color: theme.palette.text.secondary,
        },

        "&.MuiFormLabel-colorSuccess": {
          color: theme.palette.success.dark,
        },
      },

      "& .MuiInputBase-root": {
        fontSize: theme.typography.medium.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: "8px",
        background: theme.palette.background.light,

        "& .MuiInputBase-input": {
          // leave some top spacing when scroll to view
          scrollMarginTop: "20px",
        },

        // errored input root style
        "&.Mui-error": {
          background: theme.palette.error.light,
        },

        // disabled input root style
        "&.Mui-disabled": {
          background: theme.palette.secondary.light,
        },

        // color for icons used within the textField, circular progress is special case
        "& .MuiCircularProgress-root, & .MuiSvgIcon-root,": {
          color: theme.palette.primary.dark,
        },

        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px", //overwrite default 2px for focused
        },

        // style for disabled input
        "& .MuiInputBase-input.Mui-disabled": {
          // mui uses -webkit-text-fill-color for some reason, works in firefox as well
          WebkitTextFillColor: theme.palette.secondary.main,
        },

        // focus style for non-error inputs
        "&.Mui-focused.MuiInputBase-colorPrimary:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.dark,
        },

        // style for color="success"
        "&.MuiInputBase-colorSuccess .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.success.main,
          //simulate 3px outer border in design
          boxShadow: `0px 0px 0px 4px ${theme.palette.success.light}`,
        },
      },

      "& .MuiFormHelperText-root": {
        marginLeft: 0,
      },
    }),
  },
};

const components = { MuiTextField };
export default components;
