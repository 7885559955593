import { ComponentOptions } from "../@types/util";

const MuiAccordion: ComponentOptions<"MuiAccordion"> = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      "&::before": {
        height: 0,
      },
      "&.Mui-expanded": {
        marginBottom: 0,
        marginTop: 16,
      },
      "&.Mui-disabled": {
        opacity: 1,
        backgroundColor: "transparent",
        color: "black",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        color: theme.palette.primary.dark,
      },
    }),
  },
};

const MuiAccordionSummary: ComponentOptions<"MuiAccordionSummary"> = {
  styleOverrides: {
    root: {
      padding: 0,
      "&.Mui-expanded": {
        minHeight: 48,
        marginBottom: 0,
      },
      alignItems: "flex-start",
      minHeight: 37,
    },
    content: {
      margin: 0,
      "&.Mui-expanded": {
        margin: 0,
      },
    },
  },
};

const MuiAccordionDetails: ComponentOptions<"MuiAccordionDetails"> = {
  styleOverrides: {
    root: {
      padding: "0 0 0 0",
    },
  },
};

const components = { MuiAccordion, MuiAccordionDetails, MuiAccordionSummary };
export default components;
