const getEnv = () => {
  let base: { [key: string]: string | undefined } = {};
  if (typeof window === "undefined") {
    // Server
    base = process.env;
  } else {
    base = window.env || {};
  }

  return base;
};

export default getEnv;
