import { useMemo } from "react";
import { Order } from "@models/order";
import { Divider, Stack, StackProps } from "@mui/material";
import OrderTotal from "./OrderTotal";
import PayLinkMemoDescription from "./PayLinkMemoDescription";
import ProductList from "./ProductList";
import PartialAmount from "./PartialAmount";

type Props = {
  order: Order;
  nonHeaderDisplay?: boolean;
  quantityEditable?: boolean;
  showRemove?: boolean;
} & Omit<StackProps, "order">;

export default function OrderSummary({ order, nonHeaderDisplay, quantityEditable, showRemove, ...stackProps }: Props) {
  const shouldShowPartialAmount = useMemo(() => {
    if (!order?.payment?.transactionAmount?.value) return false;
    return order?.payment?.transactionAmount?.value !== order?.pricing?.total?.value;
  }, [order?.payment?.transactionAmount, order?.pricing?.total?.value]);

  return (
    <Stack spacing={2} {...stackProps}>
      <ProductList
        nonHeaderDisplay={nonHeaderDisplay}
        items={order.items}
        order={order}
        quantityEditable={quantityEditable}
        showRemove={showRemove}
      />
      {(order.memo || order.description) && (
        <PayLinkMemoDescription memo={order.memo} description={order.description} />
      )}
      <Divider />
      <OrderTotal order={order} />
      {shouldShowPartialAmount && <PartialAmount partialAmount={order?.payment?.transactionAmount?.value as number} />}
    </Stack>
  );
}
