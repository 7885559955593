import { Shadows } from "@mui/material";

export type CustomShadows = ["none", ...string[]];

export function mergeShadows(defaultShadows: Shadows, customShadows: CustomShadows): Shadows {
  if (!customShadows.length) {
    return defaultShadows;
  }
  return defaultShadows.map((s, i) => (i < customShadows.length ? customShadows[i] : s)) as Shadows;
}
