export enum IN_MEMORY_STORAGE_KEYS {
  SESSION,
}

class InMemoryStorage {
  storage = new Map<IN_MEMORY_STORAGE_KEYS, unknown>();

  getItem = (key: IN_MEMORY_STORAGE_KEYS): unknown => {
    return this.storage.get(key);
  };

  deleteItem = (key: IN_MEMORY_STORAGE_KEYS) => {
    this.storage.delete(key);
  };

  setItem = (key: IN_MEMORY_STORAGE_KEYS, value: unknown) => {
    this.storage.set(key, value);
  };
}

const inMemoryStorage = new InMemoryStorage();
export default inMemoryStorage;
