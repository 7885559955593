import { ComponentOptions } from "../@types/util";
import React from "react";

import { Report, Warning, CheckCircle } from "@mui/icons-material";

const MuiAlert: ComponentOptions<"MuiAlert"> = {
  defaultProps: {
    severity: "error",
    iconMapping: {
      success: React.createElement(CheckCircle, { sx: { fontSize: 16 } }),
      error: React.createElement(Report, { sx: { fontSize: 16 } }),
      warning: React.createElement(Warning, { sx: { fontSize: 16 } }),
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: "49px",
      borderRadius: "12px",
      padding: 0,
      display: "flex",
      alignItems: "center",
      lineHeight: 1.5,
      "& .MuiAlert-icon": {
        color: theme.palette.text.white,
        alignSelf: "stretch",
        borderRadius: "10px 0px 0px 10px",
        display: "flex",
        alignItems: "flex-start",
        padding: "14px 8px",
      },
    }),
    message: {
      paddingRight: 8,
    },
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.error.light,
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.dark,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.error.main,
      },
    }),
    standardWarning: ({ theme }) => ({
      border: `1px solid ${theme.palette.warning.main}`,
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.dark,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.warning.main,
      },
    }),
    standardSuccess: ({ theme }) => ({
      backgroundColor: theme.palette.success.light,
      border: `solid 1px ${theme.palette.success.main}`,
      color: theme.palette.success.dark,
      "& .MuiAlert-icon": {
        backgroundColor: theme.palette.success.main,
      },
    }),
  },
};

const components = { MuiAlert };
export default components;
