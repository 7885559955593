import React, { useState } from "react";
import { Tooltip, IconButton, Stack, TooltipProps } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

/**
 * Props definition for the CustomInfoTooltip component.
 *
 * @typedef {Object} CustomInfoTooltipProps
 *
 * @property {string} aria-label - Aria-label attribute for accessibility.
 * @property {React.ReactNode} content - Content to be shown inside the tooltip.
 * @property {("bottom" | "left" | "right" | "top" | "bottom-start" | "bottom-end" | "left-start" | "left-end" | "right-start" | "right-end" | "top-start" | "top-end")} [placement] - Position of the tooltip relative to its child.
 */
type CustomInfoTooltipProps = {
  content: React.ReactNode;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-start"
    | "bottom-end"
    | "left-start"
    | "left-end"
    | "right-start"
    | "right-end"
    | "top-start"
    | "top-end";
  arrow?: TooltipProps["arrow"];
};

/**
 * Custom tooltip component that wraps an information icon.
 *
 * @param {CustomInfoTooltipProps} props - Properties passed to the component.
 *
 * @param {string} aria-label - Aria-label attribute for accessibility.
 * @param {React.ReactNode} content - Content to be shown inside the tooltip.
 * @param {("bottom" | "left" | "right" | "top" | "bottom-start" | "bottom-end" | "left-start" | "left-end" | "right-start" | "right-end" | "top-start" | "top-end")} [placement] - Position of the tooltip relative to its child.
 * @returns {React.ReactElement} Rendered component.
 */
const CustomInfoTooltip = ({ content, placement, ...props }: CustomInfoTooltipProps) => {
  const [open, setOpen] = useState(false); // State to manage tooltip visibility

  return (
    <Tooltip
      data-testid="tooltip"
      leaveTouchDelay={10000}
      title={<Stack sx={{ display: "flex", alignItems: "end" }}>{content}</Stack>}
      placement={placement}
      open={open} // set the open prop
      onClose={() => setOpen(false)} // handle close
      onOpen={() => setOpen(true)} // handle open
      {...props}
    >
      <InfoComponent aria-label={"Hover over for more content"} />
    </Tooltip>
  );
};

/**
 * Custom IconButton component with an Info icon. This is intended to be used as the child of the CustomInfoTooltip.
 *
 * @param {React.HTMLProps<HTMLDivElement>} props - Standard HTML div properties.
 * @param {React.Ref<HTMLDivElement>} ref - Forwarded ref.
 *
 * @returns {React.ReactElement} Rendered component.
 */
const InfoComponent = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function MyComponent(
  props,
  ref,
) {
  return (
    <div {...props} ref={ref}>
      <IconButton aria-label={props["aria-label"]} sx={{ verticalAlign: "super", fontSize: "inherit", p: 0, ml: 0.25 }}>
        <InfoOutlined sx={{ fontSize: "20px" }} color="link" />
      </IconButton>
    </div>
  );
});

export default CustomInfoTooltip;
