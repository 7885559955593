import { Grid, Skeleton, Stack } from "@mui/material";
import { CardSize } from "../../payment-constants";
import { FC } from "react";

const CardLoadingSkeleton: FC<{ size?: CardSize }> = ({ size = CardSize.Large }) => {
  const cardWidth = size === undefined || size === CardSize.Large ? 180 : 85;
  const cardHeight = size === undefined || size === CardSize.Large ? 115 : 42;
  const cardBR = size === undefined || size === CardSize.Large ? 16 : 5;
  const direction = size === undefined || size === CardSize.Large ? "column" : "row";

  return (
    <Stack spacing={1} data-testid="cardLoadingSkeleton" direction={direction}>
      <Skeleton variant="rectangular" width={cardWidth} height={cardHeight} style={{ borderRadius: cardBR }} />
      {size === CardSize.Large || size === undefined ? (
        <Skeleton variant="rectangular" width={140} height={20} style={{ borderRadius: 16 }} />
      ) : (
        <Grid container>
          <Grid item xs={7}>
            <Skeleton variant="rectangular" width={140} height={15} style={{ borderRadius: 10 }} />
          </Grid>
          <Grid item xs={7}>
            <Skeleton variant="rectangular" width={55} height={15} style={{ borderRadius: 10 }} />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default CardLoadingSkeleton;
