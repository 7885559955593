import { FC } from "react";

import { Stack } from "@mui/material";

import CardLoadingSkeleton from "./CardLoadingSkeleton";
import { CardSize } from "../../payment-constants";

const CardLoadingSkeletonList: FC<{ numRows?: number; size?: CardSize }> = ({ numRows = 1, size = CardSize.Large }) => {
  return (
    <Stack spacing={3}>
      {new Array(numRows).fill(null).map((_, i) => (
        <CardLoadingSkeleton size={size} key={i} />
      ))}
    </Stack>
  );
};
export default CardLoadingSkeletonList;
