import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PoweredBySkipifyIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 140 12"
      sx={{
        color: (theme) => theme.palette.text.primary, //#1E1E1E
        width: 120,
        height: 12,
        ...sx,
      }}
      {...props}
    >
      <path d="M76.5845 8.46578L64.0532 11.9881V3.71009L76.5845 0.187256V8.46578Z" />
      <path d="M111.517 2.33933C111.517 1.73348 111.776 1.42052 112.271 1.42052C112.508 1.42052 112.831 1.48344 113.046 1.56697V0.167057C112.745 0.062375 112.4 0 111.991 0C110.569 0 109.88 1.00234 109.88 2.33933V3.00756H108.954V4.36517H109.88V9.27327H111.517V4.36517H112.809V3.00756H111.517V2.33933Z" />
      <path d="M85.8008 4.26157L84.595 3.94807C83.1733 3.57219 82.6353 3.19577 82.6353 2.48578C82.6353 1.77579 83.2812 1.37876 84.3153 1.37876C85.3495 1.37876 86.0816 1.94285 86.1459 2.81989H87.7823C87.6744 1.04465 86.4043 0 84.3578 0C82.4194 0 81.0195 1.08641 81.0195 2.58992C81.0195 3.864 81.8595 4.7622 83.2807 5.11747L84.3791 5.38866C85.8008 5.74393 86.3606 6.11981 86.3606 6.85095C86.3606 7.64447 85.6498 8.10387 84.487 8.10387C83.2382 8.10387 82.4194 7.37273 82.3551 6.20333H80.6968C80.7617 8.22917 82.2046 9.48209 84.444 9.48209C86.5547 9.48209 87.9759 8.37507 87.9759 6.7045C87.9759 5.45104 87.2007 4.61575 85.8008 4.26103V4.26157Z" />
      <path d="M108.133 3.00879H106.496V9.2745H108.133V3.00879Z" />
      <path d="M118.256 3.02905L116.528 7.84061L114.831 3.02905H113L115.671 9.83553L114.788 11.9888H116.425L120 3.02905H118.256Z" />
      <path d="M107.341 0.0622559C106.738 0.0622559 106.286 0.479897 106.286 1.0646C106.286 1.64929 106.738 2.04632 107.341 2.04632C107.944 2.04632 108.353 1.62868 108.353 1.0646C108.353 0.500508 107.923 0.0622559 107.341 0.0622559Z" />
      <path d="M97.1512 3.00879H95.5142V9.2745H97.1512V3.00879Z" />
      <path d="M94.9054 3.01238H92.9457L90.5335 5.74169V0.171875H88.8965V9.27808H90.5335V6.03296L93.0749 9.27808H95.0564L92.2818 5.83173L94.9054 3.01238Z" />
      <path d="M96.3557 0.0622559C95.7528 0.0622559 95.3003 0.479897 95.3003 1.0646C95.3003 1.64929 95.7528 2.04632 96.3557 2.04632C96.9586 2.04632 97.368 1.62868 97.368 1.0646C97.368 0.500508 96.9373 0.0622559 96.3557 0.0622559Z" />
      <path d="M102.381 2.7981C101.39 2.7981 100.529 3.19513 99.9905 3.77982V3.00692H98.3535V11.9672H99.9905V8.52087C100.529 9.12672 101.369 9.48144 102.338 9.48144C104.233 9.48144 105.59 8.10269 105.59 6.13977C105.59 4.17686 104.276 2.7981 102.381 2.7981ZM101.95 8.08262C100.788 8.08262 99.9905 7.26794 99.9905 6.11916C99.9905 4.97037 100.788 4.19747 101.95 4.19747C103.113 4.19747 103.91 4.99099 103.91 6.11916C103.91 7.24733 103.113 8.08262 101.95 8.08262Z" />
      <path d="M5.52224 3.84988C5.52224 4.25827 5.42459 4.64114 5.22931 4.99848C5.04125 5.35583 4.74109 5.64389 4.32882 5.86267C3.92379 6.08146 3.41026 6.19085 2.78824 6.19085H1.51889V9.12253H0V1.48703H2.78824C3.3741 1.48703 3.87316 1.58913 4.28543 1.79333C4.6977 1.99752 5.00509 2.27829 5.20761 2.63564C5.41736 2.99298 5.52224 3.39773 5.52224 3.84988ZM2.72315 4.95473C3.14265 4.95473 3.45366 4.85992 3.65618 4.67031C3.85869 4.47341 3.95995 4.19993 3.95995 3.84988C3.95995 3.10602 3.54768 2.73409 2.72315 2.73409H1.51889V4.95473H2.72315Z" />
      <path d="M8.76097 9.22098C8.18235 9.22098 7.66159 9.09336 7.19869 8.83811C6.73579 8.57557 6.37053 8.20729 6.10292 7.73326C5.84254 7.25923 5.71235 6.71228 5.71235 6.0924C5.71235 5.47251 5.84616 4.92556 6.11377 4.45153C6.38862 3.9775 6.76111 3.61286 7.23124 3.35762C7.70137 3.09508 8.22574 2.96381 8.80437 2.96381C9.38299 2.96381 9.90737 3.09508 10.3775 3.35762C10.8476 3.61286 11.2165 3.9775 11.4841 4.45153C11.759 4.92556 11.8964 5.47251 11.8964 6.0924C11.8964 6.71228 11.7553 7.25923 11.4733 7.73326C11.1984 8.20729 10.8223 8.57557 10.345 8.83811C9.87482 9.09336 9.34683 9.22098 8.76097 9.22098ZM8.76097 7.88641C9.03582 7.88641 9.29258 7.82077 9.53126 7.6895C9.77718 7.55094 9.97246 7.34675 10.1171 7.07691C10.2618 6.80708 10.3341 6.47891 10.3341 6.0924C10.3341 5.51627 10.1822 5.07506 9.87844 4.76876C9.58189 4.45518 9.21664 4.29838 8.78267 4.29838C8.3487 4.29838 7.98345 4.45518 7.6869 4.76876C7.39759 5.07506 7.25293 5.51627 7.25293 6.0924C7.25293 6.66852 7.39397 7.11338 7.67605 7.42697C7.96536 7.73326 8.327 7.88641 8.76097 7.88641Z" />
      <path d="M20.8342 3.06226L19.0766 9.12253H17.4384L16.3426 4.88909L15.2468 9.12253H13.5978L11.8293 3.06226H13.3699L14.4331 7.67857L15.5832 3.06226H17.1888L18.3172 7.66763L19.3804 3.06226H20.8342Z" />
      <path d="M26.7348 5.96113C26.7348 6.17991 26.7203 6.37681 26.6914 6.55184H22.2974C22.3336 6.9894 22.4855 7.33216 22.7531 7.58011C23.0207 7.82807 23.3498 7.95204 23.7404 7.95204C24.3045 7.95204 24.706 7.70774 24.9446 7.21912H26.5829C26.4093 7.80254 26.0766 8.28386 25.5848 8.66309C25.0929 9.03502 24.489 9.22098 23.7729 9.22098C23.1943 9.22098 22.6736 9.09336 22.2107 8.83811C21.755 8.57557 21.397 8.20729 21.1366 7.73326C20.8834 7.25923 20.7569 6.71228 20.7569 6.0924C20.7569 5.46522 20.8834 4.91462 21.1366 4.44059C21.3897 3.96656 21.7441 3.60192 22.1998 3.34668C22.6555 3.09143 23.1798 2.96381 23.7729 2.96381C24.3443 2.96381 24.8542 3.08779 25.3027 3.33574C25.7583 3.58369 26.1091 3.93739 26.355 4.39683C26.6082 4.84898 26.7348 5.37041 26.7348 5.96113ZM25.1616 5.52356C25.1544 5.12975 25.0134 4.81617 24.7385 4.5828C24.4637 4.34214 24.1273 4.22181 23.7295 4.22181C23.3534 4.22181 23.0352 4.33849 22.7748 4.57186C22.5217 4.79793 22.3662 5.11517 22.3083 5.52356H25.1616Z" />
      <path d="M28.8537 4.00303C29.049 3.68214 29.3022 3.43055 29.6132 3.24823C29.9314 3.06591 30.2931 2.97475 30.6981 2.97475V4.5828H30.2967C29.8193 4.5828 29.4577 4.69584 29.2118 4.92191C28.9731 5.14799 28.8537 5.54179 28.8537 6.10333V9.12253H27.3349V3.06226H28.8537V4.00303Z" />
      <path d="M36.7999 5.96113C36.7999 6.17991 36.7855 6.37681 36.7565 6.55184H32.3626C32.3988 6.9894 32.5507 7.33216 32.8183 7.58011C33.0859 7.82807 33.415 7.95204 33.8056 7.95204C34.3697 7.95204 34.7711 7.70774 35.0098 7.21912H36.648C36.4744 7.80254 36.1417 8.28386 35.6499 8.66309C35.1581 9.03502 34.5541 9.22098 33.8381 9.22098C33.2595 9.22098 32.7387 9.09336 32.2758 8.83811C31.8202 8.57557 31.4621 8.20729 31.2017 7.73326C30.9486 7.25923 30.822 6.71228 30.822 6.0924C30.822 5.46522 30.9486 4.91462 31.2017 4.44059C31.4549 3.96656 31.8093 3.60192 32.265 3.34668C32.7206 3.09143 33.245 2.96381 33.8381 2.96381C34.4095 2.96381 34.9194 3.08779 35.3678 3.33574C35.8235 3.58369 36.1743 3.93739 36.4202 4.39683C36.6734 4.84898 36.7999 5.37041 36.7999 5.96113ZM35.2268 5.52356C35.2196 5.12975 35.0785 4.81617 34.8037 4.5828C34.5288 4.34214 34.1925 4.22181 33.7947 4.22181C33.4186 4.22181 33.1004 4.33849 32.84 4.57186C32.5868 4.79793 32.4313 5.11517 32.3735 5.52356H35.2268Z" />
      <path d="M37.0095 6.07052C37.0095 5.45793 37.1288 4.91462 37.3675 4.44059C37.6134 3.96656 37.9461 3.60192 38.3656 3.34668C38.7851 3.09143 39.2516 2.96381 39.7651 2.96381C40.1557 2.96381 40.5282 3.05132 40.8826 3.22635C41.237 3.39408 41.5191 3.62016 41.7288 3.90457V1.02759H43.2694V9.12253H41.7288V8.22552C41.5408 8.52452 41.2768 8.76518 40.9369 8.9475C40.5969 9.12982 40.2027 9.22098 39.7543 9.22098C39.248 9.22098 38.7851 9.08971 38.3656 8.82717C37.9461 8.56463 37.6134 8.19635 37.3675 7.72232C37.1288 7.241 37.0095 6.6904 37.0095 6.07052ZM41.7397 6.0924C41.7397 5.72047 41.6674 5.40323 41.5227 5.14069C41.3781 4.87086 41.1828 4.66666 40.9369 4.5281C40.6909 4.38225 40.4269 4.30932 40.1449 4.30932C39.8628 4.30932 39.6024 4.3786 39.3637 4.51716C39.125 4.65573 38.9298 4.85992 38.7779 5.12975C38.6332 5.39229 38.5609 5.70588 38.5609 6.07052C38.5609 6.43515 38.6332 6.75603 38.7779 7.03316C38.9298 7.30299 39.125 7.51083 39.3637 7.65669C39.6096 7.80254 39.87 7.87547 40.1449 7.87547C40.4269 7.87547 40.6909 7.80619 40.9369 7.66763C41.1828 7.52177 41.3781 7.31758 41.5227 7.05504C41.6674 6.78521 41.7397 6.46432 41.7397 6.0924Z" />
      <path d="M47.8398 3.94833C48.0351 3.65662 48.3027 3.41961 48.6427 3.23729C48.9898 3.05497 49.384 2.96381 49.8252 2.96381C50.3387 2.96381 50.8017 3.09143 51.2139 3.34668C51.6334 3.60192 51.9625 3.96656 52.2012 4.44059C52.4471 4.90733 52.5701 5.45063 52.5701 6.07052C52.5701 6.6904 52.4471 7.241 52.2012 7.72232C51.9625 8.19635 51.6334 8.56463 51.2139 8.82717C50.8017 9.08971 50.3387 9.22098 49.8252 9.22098C49.3768 9.22098 48.9826 9.13347 48.6427 8.95844C48.31 8.77612 48.0423 8.54276 47.8398 8.25834V9.12253H46.3209V1.02759H47.8398V3.94833ZM51.0186 6.07052C51.0186 5.70588 50.9427 5.39229 50.7908 5.12975C50.6461 4.85992 50.4509 4.65573 50.2049 4.51716C49.9663 4.3786 49.7059 4.30932 49.4238 4.30932C49.149 4.30932 48.8886 4.38225 48.6427 4.5281C48.404 4.66666 48.2087 4.87086 48.0568 5.14069C47.9121 5.41052 47.8398 5.72776 47.8398 6.0924C47.8398 6.45703 47.9121 6.77427 48.0568 7.0441C48.2087 7.31393 48.404 7.52177 48.6427 7.66763C48.8886 7.80619 49.149 7.87547 49.4238 7.87547C49.7059 7.87547 49.9663 7.80254 50.2049 7.65669C50.4509 7.51083 50.6461 7.30299 50.7908 7.03316C50.9427 6.76333 51.0186 6.44245 51.0186 6.07052Z" />
      <path d="M58.9153 3.06226L55.194 11.9886H53.5775L54.8794 8.96938L52.4709 3.06226H54.1742L55.7257 7.2957L57.2988 3.06226H58.9153Z" />
    </SvgIcon>
  );
}
