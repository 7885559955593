import { ComponentOptions } from "../@types/util";

const MuiTypography: ComponentOptions<"MuiTypography"> = {
  defaultProps: {
    variant: "small",
    variantMapping: {
      huge: "h1",
      xxl: "h2",
      xl: "h3",
      large: "p",
      medium: "p",
      small: "p",
      xs: "p",
      micro: "small",
    },
  },
};

const components = { MuiTypography };
export default components;
