import { useFlags } from "@hooks/ldWithOverrides";
import { usePathname } from "next/navigation";
import skipifyEvents from "@services/skipifyEvents";
import { FlowTypes } from "@constants/skipifyEvent";

const useSkipifyLayer: () => boolean = () => {
  const { skipifyLayer, samsungDemo } = useFlags();
  const pathname = usePathname();
  // We currently (3/26/24) do not want to support v2 styles on the button flow, until we implement v2 for button fully
  // Samsung demo is techincally a button flow and we do want it displayed in v2 there
  return (
    skipifyLayer && pathname?.includes("embed") && (skipifyEvents.flowType !== FlowTypes.BUTTON_CHECKOUT || samsungDemo)
  );
};

export default useSkipifyLayer;
