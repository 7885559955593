const initMocks: () => Promise<ServiceWorkerRegistration | undefined> = async () => {
  if (typeof window === "undefined") {
    const { server } = await import("./server");
    server.listen();
    return;
  }

  const { worker } = await import("./browser");
  return worker.start({
    onUnhandledRequest(req) {
      //do not warn about next static request
      if (req.url.pathname.startsWith("/_next/static/")) {
        return;
      }
    },
  });
};

export default initMocks;
