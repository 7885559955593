import axios from "axios";
import { ApiResponse, AuthServiceUserResponse, UserResponse } from "@models/api/apiResponses";
import transformRequestWithAuthorization from "./utils/transformRequestWithAuthorization";
import interceptUnauthorized from "./utils/interceptUnauthorized";
import axiosRetry from "@services/utils/axiosRetry";
import interceptWithFingerprint from "@services/utils/interceptWithFingerprint";
import { addSentryInterceptors } from "./utils/interceptSentry";
import interceptWithErrorLogs from "@services/utils/interceptWithErrorLogs";
import { PatchMeRequestBody } from "@models/api/apiRequests";
import getEnv from "@utils/getEnv";

const BASE_URL = getEnv().AUTH_ROOT || `${getEnv().API_ROOT}/v3/auth-service`;

const API_MODULE = "shoppers/v1";
const ME_ENDPOINT = "/identity/me";
const GET_TOKEN_ENDPOINT = "/identity/me/session/token";
const DELETE_SESSION_ENDPOINT = "/identity/me/session";

const userApiV3 = axios.create({
  baseURL: `${BASE_URL}/${API_MODULE}`,
  withCredentials: true,
  transformRequest: [transformRequestWithAuthorization],
});

addSentryInterceptors(userApiV3);
const unauthorizedInterceptorId = userApiV3.interceptors.response.use(undefined, interceptUnauthorized);
userApiV3.interceptors.response.use(undefined, interceptWithErrorLogs);
userApiV3.interceptors.request.use(interceptWithFingerprint);
axiosRetry(userApiV3);

const withoutInterceptor =
  <T>(interceptorId: number, fn: () => Promise<ApiResponse<T>>) =>
  async (): Promise<ApiResponse<T>> => {
    userApiV3.interceptors.response.eject(interceptorId);
    const result = await fn();
    userApiV3.interceptors.response.use(undefined, interceptUnauthorized);
    return result;
  };

export const getUser = async (): Promise<ApiResponse<UserResponse | AuthServiceUserResponse>> => {
  const res = await userApiV3.get<ApiResponse<UserResponse | AuthServiceUserResponse>>(ME_ENDPOINT);
  return res.data;
};

export const patchMe = async (
  patchMeRequestBody: PatchMeRequestBody,
): Promise<ApiResponse<UserResponse | AuthServiceUserResponse>> => {
  const res = await userApiV3.patch<ApiResponse<UserResponse | AuthServiceUserResponse>>(
    ME_ENDPOINT,
    patchMeRequestBody,
  );
  return res.data;
};

export const getToken = async (): Promise<ApiResponse<string>> => {
  const res = await userApiV3.get<ApiResponse<string>>(GET_TOKEN_ENDPOINT);
  return res.data;
};

export const signOut = async (): Promise<ApiResponse<string>> => {
  const res = await userApiV3.delete<ApiResponse<string>>(DELETE_SESSION_ENDPOINT);
  return res.data;
};

export async function setDefaultIdentityId(id: string): Promise<ApiResponse<AuthServiceUserResponse>> {
  const response = await userApiV3.patch<ApiResponse<AuthServiceUserResponse>>(
    ME_ENDPOINT,
    {
      defaultIdentityId: id,
    },
    { headers: { "Content-Type": "application/json" } },
  );
  return response.data;
}

export const getUserWithoutInterceptor = withoutInterceptor(unauthorizedInterceptorId, getUser);
export const getTokenWithoutInterceptor = withoutInterceptor(unauthorizedInterceptorId, getToken);
export const deleteSessionWithoutInterceptor = withoutInterceptor(unauthorizedInterceptorId, signOut);
