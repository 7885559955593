import Image from "next/image";

import { Stack, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import white from "@images/skipify_logos/white.svg";

type SplashScreenProps = {
  progress?: number;
};

function SplashScreen({ progress }: SplashScreenProps) {
  return (
    <>
      <LinearProgress
        sx={{
          background: "rgba(220, 222, 229, 0.2)",
        }}
        color="brand"
        variant={progress != null ? "determinate" : "indeterminate"}
        value={progress}
      />
      <Stack>
        <Stack
          direction="row"
          sx={{
            marginTop: "26.5px",
          }}
        >
          <Typography
            variant="xl"
            sx={{
              marginRight: "9.5px",
            }}
          >
            See it. Buy it.
          </Typography>
          <Image src={white} width={95} alt="Skipify" />
        </Stack>
        <Typography
          variant="medium"
          sx={{
            marginTop: "8.5px",
          }}
        >
          The Modern Checkout Platform.
        </Typography>
      </Stack>
    </>
  );
}

export default SplashScreen;
