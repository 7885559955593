import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { useFlags as originalUseFlags } from "launchdarkly-react-client-sdk";

import getEnv from "@utils/getEnv";

import useLocalStorage from "./useLocalStorage";

export const useFlags: <T extends LDFlagSet = LDFlagSet>() => T = <T extends LDFlagSet = LDFlagSet>(): T => {
  const originalFlags = originalUseFlags<T>();

  const [overridesEnabled] = useLocalStorage("ldovrdsenbld", false);
  const [overrides] = useLocalStorage<T>("ldovrds", {} as T);

  if (!["local", "dev", "test", "sandbox"].includes(getEnv()?.ENV || "")) {
    return originalFlags;
  }

  if (!overridesEnabled) {
    return originalFlags;
  }

  return { ...originalFlags, ...overrides };
};
