import accordion from "./accordion";
import button from "./button";
import checkbox from "./checkbox";
import chip from "./chip";
import dialog from "./dialog";
import radio from "./radio";
import tooltip from "./tooltip";
import typography from "./typography";
import link from "./link";
import textfield from "./textfield";
import formControlLabel from "./formControlLabel";
import circularProgress from "./circularProgress";
import alert from "./alert";
import muiSwitch from "./switch";

/**
 * This is the complete set of defaults and overrides that we used for Shakira v1.
 * Some of this may not be relevant to v2 but this is here for the framework of organizing
 * components for the theme.
 */
const components = {
  ...accordion,
  ...alert,
  ...button,
  ...checkbox,
  ...chip,
  ...dialog,
  ...radio,
  ...tooltip,
  ...typography,
  ...link,
  ...textfield,
  ...formControlLabel,
  ...circularProgress,
  ...muiSwitch,
};

export default components;
