import React, { ReactNode, useEffect, useState } from "react";

import useAuthStore from "@stores/authStore";
import { datadogRum } from "@datadog/browser-rum";
import { LDContext, useLDClient } from "launchdarkly-react-client-sdk";

const withUserProfile = (wrapped: ReactNode) => {
  const WithUserProfile = () => {
    const { getUserProfile, user } = useAuthStore();
    const [identifiedWithLD, setIdentifiedWithLD] = useState(false);
    const ldClient = useLDClient();

    useEffect(() => {
      if (!user) {
        getUserProfile();
        return;
      }

      if (ldClient?.variation("identify-user-with-ld", false)) {
        // if the user should be identified with LD for feature flags, this adds latency to the login logic.
        const prevCtx = ldClient.getContext();
        let nextCtx: LDContext;
        if (("user" in prevCtx && prevCtx.user.key === user.uid) || prevCtx.key === user.uid) {
          setIdentifiedWithLD(true);
          return; // the user is already identified with LD, do nothing
        }
        if ("kind" in prevCtx && prevCtx.kind === "multi") {
          nextCtx = {
            ...prevCtx,
            user: {
              key: user.uid,
              anonymous: false,
            },
          };
        } else {
          nextCtx = {
            kind: "user",
            anonymous: false,
            key: user.uid,
          };
        }

        ldClient.identify(nextCtx).finally(() => setIdentifiedWithLD(true));
      } else {
        setIdentifiedWithLD(true);
      }

      if (datadogRum?.getInternalContext()?.session_id) {
        datadogRum.setUser({ id: user.uid });
      }
    }, [getUserProfile, ldClient, user]);

    if (!user || !identifiedWithLD) return null;

    return <>{wrapped}</>;
  };

  return <WithUserProfile />;
};

export default withUserProfile;
