import { CardStatus, ConsolidatedPaymentMethod, LinkedCard, ManualCard, UnLinkedCard } from "@models/card";
import { Divider, Link, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { partition } from "lodash";
import { FC, useCallback } from "react";
import PaymentListRow from "./PaymentListRow";
import skipifyEvents from "@services/skipifyEvents";
import { FlowTypes } from "@constants/skipifyEvent";

export interface PaymentListProps {
  value?: ConsolidatedPaymentMethod;
  paymentMethods: (LinkedCard | ManualCard)[];
  externalCards?: UnLinkedCard[];
  onSelect: (selected: ConsolidatedPaymentMethod) => void;
  onSelectionError: (error: CardStatus) => void;
  onEdit: (selected: LinkedCard | ManualCard) => void;
  onAddNewPayment: () => void;
  dashboard?: boolean;
  skipifyLayer?: boolean;
}

const PaymentList: FC<PaymentListProps> = ({
  paymentMethods,
  externalCards,
  value,
  onSelect,
  onSelectionError,
  onAddNewPayment,
  onEdit,
  dashboard = false,
}) => {
  const [defaultPaymentMethods, restOfPaymentMethods] = partition(paymentMethods, "is_default");
  const defaultPaymentMethod = defaultPaymentMethods[0];
  const handleSelect = useCallback(
    (selected: ConsolidatedPaymentMethod) => {
      // card isn't selectable because of card linking error
      const selectedCardError = selected.card_status;
      if (selectedCardError !== undefined && skipifyEvents.flowType !== FlowTypes.SHOPPER_DASHBOARD) {
        onSelectionError(selectedCardError);
      }
      onSelect(selected);
    },
    [onSelect, onSelectionError],
  );

  const isItemChecked = (item: ConsolidatedPaymentMethod): boolean => {
    if (!value) {
      return false;
    }

    if ("card_id" in value && "card_id" in item) {
      return value.card_id === item.card_id;
    } else if ("card_metadata_id" in value && "card_metadata_id" in item) {
      return value.card_metadata_id === item.card_metadata_id;
    }
    return false;
  };

  const cardDisabled = (card: ConsolidatedPaymentMethod) => {
    // currently-- card status undefined if card has no errors
    return skipifyEvents.flowType !== FlowTypes.SHOPPER_DASHBOARD && Boolean(card?.card_status);
  };

  const paymentMethodItems = restOfPaymentMethods.map((paymentMethod) => (
    <PaymentListRow
      key={paymentMethod.card_id}
      item={paymentMethod}
      onEdit={onEdit}
      isDefault={paymentMethod.is_default}
      onSelect={handleSelect}
      checked={isItemChecked(paymentMethod)}
      disabled={cardDisabled(paymentMethod)}
      dashboard={dashboard}
    />
  ));

  const externalCardItems =
    externalCards &&
    externalCards.map((card) => (
      <PaymentListRow
        key={card.card_metadata_id}
        item={card}
        onSelect={handleSelect}
        checked={isItemChecked(card)}
        disabled={cardDisabled(card)}
        dashboard={dashboard}
      />
    ));

  return (
    <>
      {!dashboard && (
        <Typography variant="medium" fontWeight="bold">
          Wallet
        </Typography>
      )}
      {paymentMethods.length > 0 && (
        <>
          <Typography sx={{ mt: 2, color: dashboard ? "text.secondary" : "inherit" }} variant="subtitle2">
            Saved cards
          </Typography>

          <Stack mt={2} spacing={4}>
            {defaultPaymentMethod && (
              <PaymentListRow
                key={defaultPaymentMethod.card_id}
                item={defaultPaymentMethod}
                onEdit={onEdit}
                isDefault={defaultPaymentMethod.is_default}
                onSelect={onSelect}
                checked={isItemChecked(defaultPaymentMethod)} //note: UI does not select default paymethod, the backend automatically does
                disabled={cardDisabled(defaultPaymentMethod)}
                dashboard={dashboard}
              />
            )}
            {paymentMethodItems}
          </Stack>
          {!dashboard && <Divider sx={{ mb: 2, mt: 2 }} />}
        </>
      )}

      {externalCardItems && !!externalCardItems.length && (
        <>
          {!dashboard ? (
            <>
              <Typography variant="medium" color="text.secondary">
                More options
              </Typography>
              <Typography variant="medium">
                We found additional cards on the network. You can add them to your linked payment methods.
              </Typography>
            </>
          ) : (
            <Typography variant="medium">Link to saved cards.</Typography>
          )}

          <Stack mt={2} spacing={4}>
            {externalCardItems}
          </Stack>
          {!dashboard && <Divider sx={{ mb: 2, mt: 2 }} />}
        </>
      )}

      <Stack mt={2} spacing={4} paddingBottom={"8px"} paddingTop={"16px"}>
        <Link role="button" onClick={onAddNewPayment} variant="button">
          Add card
        </Link>
      </Stack>
    </>
  );
};

export default PaymentList;
