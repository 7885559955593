import { SplashScreen } from "@components/SplashScreen";
import UnrecoverableError from "@components/UnrecoverableError";
import { Container } from "@mui/material";

type InitialLoadingProps = {
  progress?: number;
  error: string;
};

function InitialLoading({ progress, error }: InitialLoadingProps) {
  return (
    <Container
      sx={{
        color: "white",
        background: "#1e1e1e",
        height: "100vh",
        position: "fixed",
        zIndex: 1, // Keep this at 1 so content (header and footer) remains hidden when rendered behind the main loading screen
      }}
      maxWidth={false}
    >
      {!error ? <SplashScreen progress={progress} /> : <UnrecoverableError loadingError={error} />}
    </Container>
  );
}

export default InitialLoading;
