import "simplebar-react/dist/simplebar.min.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ComponentType, ReactNode, useEffect } from "react";

import { BasicLayout } from "@components/Layout";
import withUserProfile from "@hocs/withUserProfile";
import { NextPageWithLayout } from "@models/nextPageWithLayout";
import themes from "@themes/index";
// Initialize datadog
import "@services/datadogLog";
import "@services/datadogRum";
import { datadogRum } from "@datadog/browser-rum";

import "@styles/globals.css";

import { StepupListener } from "@components/ExternalContent";
import isInIframe from "@utils/isInIframe";
import { withLDProvider } from "launchdarkly-react-client-sdk";

import useCommonInitialization from "@hooks/useCommonInitialization";
import getEnv from "@utils/getEnv";
import "@utils/sentryClient";

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { theme, appReady, safeDevMode } = useCommonInitialization(undefined, themes.default);
  const router = useRouter();

  // If we're not in safe dev mode - deny embedded pages not in the iframe
  useEffect(() => {
    if (
      !safeDevMode &&
      !isInIframe() &&
      (router.pathname.includes("/embed/") || router.pathname.includes("/example"))
    ) {
      router.replace("/404");
    }
  }, [router, safeDevMode]);

  // renders error pages without Layout components
  if (router.pathname === "/_error") return <Component {...pageProps} />;

  const getLayout = Component.getLayout || ((page: ReactNode) => <BasicLayout>{page}</BasicLayout>);

  const protectionWrapper = Component.isProtected ? withUserProfile : (e: ReactNode) => e;

  const renderedComponent = appReady ? protectionWrapper(getLayout(<Component {...pageProps} />)) : null;

  return (
    <>
      <Head>
        <title>Skipify - Delightfully efficient checkout, whenever wherever.</title>
        <meta
          name="description"
          content="Instant, secure, and delightfully efficient. Skipify has cracked the code on a more modern approach to digital checkout."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StepupListener />
        {renderedComponent}
      </ThemeProvider>
    </>
  );
}

export default withLDProvider({
  clientSideID: getEnv().LAUNCHDARKLY_CLIENT_SIDE_ID || "",
  timeout: 2,
  options: {
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (key, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
})(MyApp as unknown as ComponentType);
