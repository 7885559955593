import { useState, useRef, useEffect, RefObject } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { Stack, Drawer, Dialog, AppBar, Theme, useMediaQuery } from "@mui/material";
import SimpleBar from "simplebar-react";

import { OrderSummary } from "@components/OrderSummary";

import { Order } from "@models/order";
import mint from "@images/skipify_logos/mint.svg";
import InferRef from "@utils/InferRefType";
import useMerchantConfigStore from "@stores/merchantConfigStore";

import Cart from "./Cart";

const HEADER_HEIGHT = "108px";

export interface HeaderProps {
  storeLogo?: string;
  order?: Order;
  paylinkDesktopOverride?: boolean;
  showCartOnDesktop?: boolean;
  hideCart?: boolean;
  scrollRef?: RefObject<InferRef<typeof SimpleBar>>;
}

export default function Header({
  storeLogo,
  order,
  showCartOnDesktop,
  hideCart = false,
  scrollRef,
  paylinkDesktopOverride = false,
}: HeaderProps) {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [toggleCart, setToggleCart] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const ref = useRef(null);
  const toggleDrawer = (open: boolean) => () => setToggleCart(open);

  const pathname = usePathname();
  const reviewPayPath = pathname?.includes("reviewpay");
  const orderCompletePath = pathname?.includes("ordercomplete");
  const isPaylink = pathname?.includes("paylink");

  // When enabled, cart edits are not allowed
  const { enabled: editCartNotAllowed } = useMerchantConfigStore((store) => store.cartConfigs.DISALLOW_CART_EDITS);
  // Cart items can be removed when flow is not paylinks and when a merchant allows it in their configs
  const canItemBeRemoved = !isPaylink && !editCartNotAllowed;
  const canItemBeEditable = canItemBeRemoved && Boolean(reviewPayPath && order?.pricing.total);

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.axis.y.isOverflowing = !toggleCart;
      scrollRef.current.toggleTrackVisibility("y");
    }
  }, [scrollRef, toggleCart]);

  return (
    <header data-testid="header" ref={ref} style={{ height: HEADER_HEIGHT }}>
      <AppBar
        color="inherit"
        position="sticky"
        elevation={0}
        sx={{ height: "100%", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Stack sx={{ height: "100%" }} direction="row" justifyContent="space-between" alignItems="center">
          {storeLogo && !logoError ? (
            // As we don't know where is the image hosted, we could not use next Image optimization component
            // eslint-disable-next-line @next/next/no-img-element
            <img
              height={40}
              src={storeLogo}
              alt="Store logo"
              onError={() => {
                setLogoError(true);
              }}
            />
          ) : (
            <Image alt="Skipify" src={mint} width="40" height="40" />
          )}
          {!hideCart && (isMobileScreen || showCartOnDesktop) && !orderCompletePath && order && (
            <Stack sx={{ cursor: "pointer" }} onClick={toggleDrawer(!toggleCart)}>
              <Cart open={toggleCart} order={order} />
            </Stack>
          )}
        </Stack>
      </AppBar>
      {!hideCart && !isMobileScreen && paylinkDesktopOverride && order && (
        <Dialog
          sx={{ top: "unset", bottom: "unset" }}
          container={ref.current}
          PaperProps={{
            sx: {
              margin: "0px",
              width: "500px",
              maxHeight: "66 % ",
            },
          }}
          open={toggleCart}
          keepMounted
          onClose={toggleDrawer(!toggleCart)}
        >
          <OrderSummary order={order} padding={3} quantityEditable={canItemBeEditable} showRemove={canItemBeRemoved} />
        </Dialog>
      )}
      {!hideCart && (isMobileScreen || (showCartOnDesktop && !paylinkDesktopOverride)) && order && (
        <Drawer
          PaperProps={{ sx: { top: HEADER_HEIGHT, boxShadow: "none" } }}
          ModalProps={{ sx: { top: HEADER_HEIGHT }, BackdropProps: { sx: { top: HEADER_HEIGHT } } }}
          SlideProps={{ container: ref.current, in: toggleCart, direction: "down" }}
          anchor="top"
          open={toggleCart}
          elevation={1}
          onClose={toggleDrawer(false)}
        >
          <OrderSummary order={order} padding={3} quantityEditable={canItemBeEditable} showRemove={canItemBeRemoved} />
        </Drawer>
      )}
    </header>
  );
}
