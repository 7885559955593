import { ComponentOptions } from "../@types/util";

const MuiTooltip: ComponentOptions<"MuiTooltip"> = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      color: theme.palette.text.primary,
      background: theme.palette.background.light,
      borderRadius: 8,
      padding: "8px 12px",
      boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
      maxWidth: 320,
      minWidth: 220,
      textAlign: "center",
      fontSize: 11,
      lineHeight: "16px",
      fontWeight: 400,
    }),
    arrow: ({ theme }) => ({
      color: theme.palette.background.light,
    }),
  },
  defaultProps: {
    enterTouchDelay: 0,
  },
};

const components = { MuiTooltip };
export default components;
