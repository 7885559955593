import { AxiosError } from "axios";
import datadogLog from "@services/datadogLog";

const interceptWithErrorLogs = (error: AxiosError) => {
  // I'm creating a new error here to have a stack. AxiosError does not have one
  const e = new Error(error.message, { cause: error });
  e.name = error.name;

  datadogLog.logger.log(
    `[AxiosError] ${error.response?.config?.method} request to ${error.request?.responseURL} with ${error.response?.status}`,
    {
      requestDetails: {
        method: error.response?.config?.method,
        url: error.request?.responseURL,
        status: error.response?.status,
        response: error.response?.data,
      },
    },
    "error",
    e,
  );
  return Promise.reject(error);
};

export default interceptWithErrorLogs;
