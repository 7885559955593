import { AxiosRequestTransformer } from "axios";

import inMemoryStorage, { IN_MEMORY_STORAGE_KEYS } from "@utils/inMemoryStorage";

const transformRequestWithAuthorization: AxiosRequestTransformer = (data, headers) => {
  const session = inMemoryStorage.getItem(IN_MEMORY_STORAGE_KEYS.SESSION);
  if (headers && session) {
    headers["Authorization"] = `Bearer ${session}`;
  }
  return JSON.stringify(data);
};

export default transformRequestWithAuthorization;
